<template lang="pug">
  v-row.px-2.ma-0
    //GRID NÚMERO DE OPINIONES
    box-opiniones(:dataSummary="opinions" col="col-12")

    //GRID WEBS DE OPINIONES
    //grid-web-opiniones(v-if="!isAdverbia && !is_alsea && (typeOpinionSelected === '' || typeOpinionSelected === null || typeOpinionSelected === 'internet') " :data="dataSummary")
    grid-web-opiniones-note-real(v-if="dataSummary.length && !is_alsea && (typeOpinionSelected === '' || typeOpinionSelected === null || typeOpinionSelected === 'internet') " :data="dataSummary")

    //GRID EVOLUTION MAIN NUMBER OPINIONS
    div.margin-bottom-0.col-6(v-if="(typeOpinionSelected === 'internet' || typeOpinionSelected === ''  || typeOpinionSelected === null)")
      skeleton-card(v-if="isLoading")
      v-card.gridFull.ma-0(v-if="!isLoading && dataSummary.length")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card Evolution of the average rating and number of web opinions
          .block-help.ml-auto
            v-tooltip(left color="#222226" open-on-click=true max-width="550" content-class="tooltip-help tooltip-help-left")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              div
                p.mb-0.pb-0 En función de los filtros seleccionados, se muestra la evolución de la Valoración media y la media de las Opiniones del mes en curso y un año hacia atrás.
                p.mb-0.pb-0(v-if="!isAdverbia")  Se analiza sobre el grupo de restaurantes que marca la selección del menú izquierdo.
                p.mb-0.pb-0(v-else) Se analiza sobre el grupo de negocios que marca la selección del menú izquierdo.
        div
          box-evolution-main-number-opinion(:chartData="dataEvolutionLastYear")

    //GRID % CATEGORÍAS POR MARCA
    div.margin-bottom-0.col-12(v-if="(typeOpinionSelected === 'internet' || typeOpinionSelected === ''  || typeOpinionSelected === null)")
      skeleton-card(v-if="isLoading && !categories.length")
      v-card.gridFull.ma-0(v-if="!isLoading && categories.length" :class="{'borderDelivery': typeOpinionSelected === 'delivery'}")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card % Categories
            span.ml-1.tDelivery(v-if="typeOpinionSelected === 'delivery'") Delivery
          .block-help.ml-auto
            v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="20" nudge-left="10" content-class="tooltip-help tooltip-help-left")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              span(v-if="!isAdverbia") {{helpCategoryBrand}}
              span(v-else) {{ helpCategoryBrandAdverbia }}
            v-icon.mb-1(v-if="typeNode!=='group'" @click="goFeedback(typeOpinionSelected)") mdi-chevron-right

        .card-content
          .row.text-left
            #tableCategoriasMarcas.tableGlobal
              v-col.col-12.headerTableRanking
                v-row.headerItem.items-center.justify-start.pl-4.pt-4
                  span.mr-1.chip-legend.positive
                  translate.mr-3 Positives
                  span.mr-1.chip-legend.neutral
                  translate.mr-3 Neutral
                  span.mr-1.chip-legend.negative
                  translate.mr-3 Negatives

              v-row.headerTableRanking.no-gutters
                div.offset-1
                  span
                v-col.headerItem.justify-center(v-for="(category, indexc) in categories[0].categories" :key="indexc")
                  p.mb-0 {{ indexc }}

              v-row.contentTableRanking.no-gutters.h-auto
                p.ml-3(v-if="!categories") #[translate No data]
                div.itemContent.col-12(v-for="(item, index) in categories" :key="index")
                  v-col.col-1.d-flex.justify-start
                    span.text-start(:class="{'link-text': index > 0}" @click="()=> index > 0 ? goItemNextLevel(item) : null") {{item.name}}
                  v-col.text-center(v-for="(category, index) in item.categories_sentiment_percent" :key="index")
                    v-row.no-gutters.pb-2
                      v-col.col-6
                        p.mb-0 {{ category.percent | formatNumber }} %
                      v-col.col-6
                        p.mb-0 {{ category.count | formatNumber }}
                    v-row.no-gutters
                      bar-three-colors(:percentGreen="category.positive" :percentOrange="category.neutral" :percentRed="category.negative" :render-details="true" :total="category.count")
                div.itemContent.col-12(v-if="typeNode === 'center' && categories.length > 0 && categories[0].chain_data")
                  v-col.col-1
                    div.media-cadena.d-flex.justify-start
                      translate.text-start Chain mean
                  v-col.text-center(v-for="(category, index) in categories[0].chain_data.categories_sentiment_percent" :key="index")
                    v-row.no-gutters.pb-2
                      v-col.col-6
                        p.mb-0 {{ category.percent | formatNumber }} %
                      v-col.col-6
                        p.mb-0 {{ category.count | formatNumber }}
                    v-row.no-gutters
                      chart-bar-three-colors(:percentGreen="category.positive" :percentOrange="category.neutral" :percentRed="category.negative" :render-details="true" :total="category.count")

    //GRID EVOLUTION LAST YEAR CHART
    div.margin-bottom-0.col-12(v-if="(typeOpinionSelected === 'internet' || typeOpinionSelected === ''  || typeOpinionSelected === null)")
      skeleton-card(v-if="isLoading")
      v-card.gridFull.ma-0(v-if="!isLoading && dataSummary.length")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card Evolution of Sentiment on Opinion Websites
          .block-help.ml-auto
            v-tooltip(left color="#222226" open-on-click=true max-width="550" content-class="tooltip-help tooltip-help-left")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              div
                p.mb-0.pb-0 En función de los filtros seleccionados, se muestra la evolución del Sentimiento del mes en curso y un año hacia atrás. Una vez analizadas las opiniones de Internet obtenemos el sentimiento de las mismas, siendo los valores:
                ul(v-if="!isAdverbia")
                  li #[translate Positives]: 10 – 7,5
                  li #[translate Neutral]: 7,5 - 5
                  li #[translate Negatives]: 5 - 0
                ul(v-else)
                  li #[translate Positives]: 5 - 4,5
                  li #[translate Neutral]: 4,5 - 3
                  li #[translate Negatives]: 3 - 1
                p.mb-0.pb-0(v-if="!isAdverbia") Se analiza sobre el grupo de restaurantes que marca la selección del menú izquierdo.
                p.mb-0.pb-0(v-else) Se analiza sobre el grupo de negocios que marca la selección del menú izquierdo.
        box-evolution-last-year(:chartData="dataEvolutionLastYear" :isDelivery="false" )

    //GRID COVER MANAGER
    grid-category-cover-manager(:data="infoCoverManager" v-if="showCoverManager")

    //GRID COMPETENCIA
    grid-competencia.pa-3(:data="dataSummary")

    //GRID COMPETENCIA LAST YEAR
    div.margin-bottom-0.col-12(v-if="hasCompetence && (typeNode === 'brand' || typeNode === 'center')")
      skeleton-card(v-if="isLoading")
      v-card.gridFull.ma-0(v-if="!isLoading && dataSummary.length")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card(v-if="!isGenericEntity") Competence. Evolution of the number of opinions per restaurant
            span.mb-0.title-card(v-else) Competencia. Evolución del número de opiniones por negocio
          .block-help.ml-auto
            v-tooltip(left color="#222226" open-on-click=true max-width="550" nudge-top="20" nudge-left="10" content-class="tooltip-help tooltip-help-left")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              div
                p.mb-0.pb-0(v-if="!isAdverbia") En función de los filtros seleccionados, se muestra la evolución de los datos de la marca frente a sus competidores:  Media de las notas de todos los restaurantes /nº de restaurantes.
                p.mb-0.pb-0(v-else)  En función de los filtros seleccionados, se muestra la evolución de los datos de la marca frente a sus competidores:  Media de las notas de todos los negocios /nº de negocios.
        box-competence-last-year(:chartData="dataCompetenceLastYear")


</template>
<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import GridWebOpiniones from '@/components/global/WebOpiniones.vue'
import GridWebOpinionesNoteReal from '@/components/global/WebOpinionesNoteReal.vue'

import GridWebDelivery from '@/components/global/WebDelivery.vue'
import GridRankingRestaurantes from '@/components/global/RankingRestaurantes.vue'
import GridCompetencia from '@/components/global/Competencia.vue'
import GridCategoryCoverManager from '@/components/global/CategoryCoverManager.vue'
import ChartBarThreeColors from '@/components/charts/BarTrheeColors.vue'
import FeelingBars from '@/components/charts/FeelingBars.vue'
import BarThreeColors from '@/components/charts/BarTrheeColors.vue'
import BoxCompetenceLastYear from '@/components/network/CompetenceLastYear.vue'
import BoxEvolutionLastYear from '@/components/network/EvolutionLastYear.vue'
import BoxEvolutionMainNumberOpinion from '@/components/network/EvolutionMainNumberOpinion.vue'
import SrvNetWork from '@/services/network.js'
import SrvGlobal from '@/services/global.js'
import commonMixins from '@/mixins/common.js'
import BoxSentimiento from '@/components/common/BoxSentimiento.vue'
import BoxOpiniones from '@/components/common/BoxOpiniones.vue'

export default {
  components: {BoxOpiniones, BoxSentimiento, GridCategoryCoverManager, SkeletonCard, ChartBarThreeColors, GridWebOpinionesNoteReal, GridWebOpiniones, GridWebDelivery, GridRankingRestaurantes, GridCompetencia, FeelingBars, BarThreeColors, BoxEvolutionLastYear, BoxEvolutionMainNumberOpinion, BoxCompetenceLastYear}, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  mixins: [commonMixins],
  data() {
    return {
      dataSummary: [],
      dataEvolutionLastYear: [],
      dataEvolutionLastYearDelivery: [],
      dataCompetenceLastYear: [],
      infoCoverManager: [],
      // Help Common
      helpCategoryBrand: this.$gettext('Percentages of the different categories that have been detected in the opinions of the selected period compared to the same period of the previous year (Tend). Use the Sites selector to see only your information. And the filter to segment. It is analyzed about the group of restaurants that marks the selection in the left menu and the filters applied.'),
      helpCategoryBrandAdverbia: 'Porcentajes de las distintas categorías que se han detectado en los dictámenes del periodo seleccionado en comparación con el mismo periodo del año anterior (Tend). Utilice el selector Sitios para ver sólo su información. Y el filtro para segmentar. Se analiza sobre el grupo de negocios que marca la selección en el menú de la izquierda y los filtros aplicados.',
      helpCategoryFeeling: this.$gettext('The company´s Internet opinions are analyzed using a semantic engine and the object that is commented on in each opinion and its evaluation is obtained. In this way, the feeling towards each of the categories that are analyzed is obtained. The same criteria are used as in the global analysis, compared to the same period of the previous year (P.A.). It is analyzed about the group of restaurants that marks the selection in the left menu and the filters applied.'),
      helpCategoryFeelingAdverbia: 'Se analizan las opiniones de la empresa en Internet mediante un motor semántico y se obtiene el objeto que se comenta en cada opinión y su valoración. De esta forma se obtiene el sentimiento hacia cada una de las categorías que se analizan. Se utilizan los mismos criterios que en el análisis global, en comparación con el mismo periodo del año anterior (P.A.). Se analiza sobre el grupo de negocios que marca la selección en el menú de la izquierda y los filtros aplicados.',

      dataCategories: [],
    }
  },
  created() {
    this.$store.commit('LOADING', true)
    if (this.$store.getters.getNode > 0) {
      this.getGlobal()
      this.getEvolutionLastYear()
      if (this.hasDelivery) {
        this.getEvolutionLastYearDelivery()
      }
      if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
        this.getCompetenceLastYear()
      }
    }
  },
  computed: {
    colsCategories() {
      if (this.dataSummary && this.dataSummary[0].categories_sentiment_percent) {
        return this.dataSummary[0].categories_sentiment_percent.length <= 6 ? 'col-6' : 'col-12'
      }
      return 'col-6'
    },
    categories() {
      return this.dataSummary.filter(item => Object.values(item.categories).some(_item => _item > 0))
    },
    opinions() {
      return this.dataSummary.filter(item => item.review_count && item.review_count > 0)
    }
  },
  watch: {
    filterApply: function () {
      this.getGlobal()
      this.getEvolutionLastYear()
      if (this.hasDelivery) {
        this.getEvolutionLastYearDelivery()
      }
      if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
        this.getCompetenceLastYear()
      }
    }
  },
  methods: {
    getInfoCoverManager() {
      SrvGlobal.getInfoCoverManager()
        .then(res => {
          if (res.status === 200) {
            this.infoCoverManager = res.data
            // this.dataSummary = res.data
          }
        }, () => {
        })
    },
    getCompetenceLastYear() {
      SrvNetWork.getCompetenceLastYear()
        .then(res => {
          if (res.status === 200) {
            res.data.forEach((e) => e.show = true)
            this.dataCompetenceLastYear = res.data
          }
        }, () => {
        })
    },
    getEvolutionLastYear() {
      this.$store.commit('LOADING', true)
      SrvNetWork.getEvolutionLastYear()
        .then(res => {
          if (res.status === 200) {
            let data = res.data
            //SI ES ADVERBIA TEMPORALMENTE DIVIDIMOS ENTRE 2 EL DATO
            if (this.isAdverbia) {
              res.data.ratings = res.data.ratings.map(e => e = (Number(e) / 2))
            }
            this.dataEvolutionLastYear = data
            this.$store.commit('LOADING', false)
          }
        }, () => {
        })
    },
    getEvolutionLastYearDelivery() {
      this.$store.commit('LOADING', true)
      SrvNetWork.getEvolutionLastYear('delivery')
        .then(res => {
          if (res.status === 200) {
            this.dataEvolutionLastYearDelivery = res.data
            this.$store.commit('LOADING', false)
          }
        }, () => {
        })
    },
    getGlobal() {
      this.$store.commit('LOADING', true)
      SrvNetWork.getGlobalInternet()
        .then(async res => {
          if (res.status === 200) {
            /* ORDENAMOS ELEMENTOS SALVO EL PRIMERO*/
            const array1 = res.data.shift()
            const array2 = res.data
              .sort((a, b) => {
                // Old sort
                return a.name.localeCompare(b.name);
              })

            this.dataSummary = [].concat(array1, array2)
            if (this.showCoverManager) {
              await this.getInfoCoverManager()
            }
            this.$store.commit('LOADING', false)
          }
        }, () => {
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.colChartBrand {
  width: 10%;
}

.v-application #tableRanking .elevation-1 {
  box-shadow: none !important;
}

#tableCategoriasMarcas {
  &.tableCategoryFeeling {
    .headerItem {
      text-align: center;
      font-size: 0.75rem;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  .contentTableRanking {
    .itemContent {
      min-height: 70px !important;
    }
  }

  .col-name {
    max-width: 25%;
    flex: 0 0 25%;
    padding-left: 10px;
    text-align: left;

    &.textBlackGroup {
      color: #000;
    }

    &.media-cadena {
      color: #979797;

      span {
        letter-spacing: -0.02px;
      }
    }
  }

  .col-numero {
    flex: auto;
    margin-right: 2%;
  }
}
</style>
