// Stores
import Stores from "@/store/index";
// importamos los tipos de mutación de vuex
import * as types from "@/store/mutation-types";
// Config
import appConfig from "@/config-app.js";
// funciones comunes
import SrvCommon from "@/services/common";

// Axios
import axios from "axios";

axios.defaults.baseURL = appConfig.api;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
//
//
// business_category/
export default {
  setReply(reply_text, review_id) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/create_reply_review/`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .post(query, {reply_text, review_id})
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  deleteReply(review_id) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/delete_reply_review/${review_id}`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .delete(query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getCurrentFacebookLinkeded() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/facebook_linked_data/`;

      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `?node=${Stores.getters.getNode}`;
        }
      }

      query += `&tree=${Stores.getters.getTreeSelected.id}`;

      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getSitesLocalMarketing() {
    //https://tastia.gastroranking.pro/backend/api/v1/site_localmarketing/?center=234&tree=1
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/site_localmarketing/`;
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`;
        }
      }
      query += `&tree=${Stores.getters.getTreeSelected.id}`;

      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  connectSiteLocalMarketing(site) {
    //https://tastia.gastroranking.pro/backend/api/v1/site_localmarketing/?center=234&tree=1
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/site_localmarketing/`;
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`;
        }
      }
      query += `&tree=${Stores.getters.getTreeSelected.id}`;
      let params = {center: Stores.getters.getNode, site_code: site, sync: true};
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .post(query, params)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  updateSiteLocalMarketing(site, id) {
    //https://tastia.gastroranking.pro/backend/api/v1/site_localmarketing/?center=234&tree=1
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/site_localmarketing/${id}/`;
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`;
        }
      }
      query += `&tree=${Stores.getters.getTreeSelected.id}`;
      let params = {center: Stores.getters.getNode, site_code: site, sync: true};
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .put(query, params)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  updateSyncSiteLocalMarketing(id, state) {
    //https://tastia.gastroranking.pro/backend/api/v1/site_localmarketing/?center=234&tree=1
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/update_sync_sitelocalmark/${id}/`;
      let params = {sync: state};
      /*if(Stores.getters.getNodeLevel === 9){
        if(Stores.getters.getNode){
          query += `?center=${Stores.getters.getNode}`
        }
      }*/
      //query += `&tree=${Stores.getters.getTreeSelected.id}`
      //let params = {center: Stores.getters.getNode, site_code: site, sync: true }
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .put(query, params)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getLocalMarketing(section = "") {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/local_marketing/`;
      query = SrvCommon.getBasicParamsQuery(query);

      if (section !== "") {
        query += `&section=${section}`;
      }
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach((type) => {
          query += `&center_model=${type}`;
        });
      }
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          Stores.commit(types.CAN_MODIFY_SERVICES_LIST, res.data.can_modify_services_list);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getLocalMarketingBusinessCategory() {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/business_category/`;
      query += `?center=${Stores.getters.getNode}`;
      query += `&tree=${Stores.getters.getTreeSelected.id}`;
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getLocalMarketingPriceRange() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/price_range/`;
      query += `?center=${Stores.getters.getNode}`;
      query += `&tree=${Stores.getters.getTreeSelected.id}`;
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  syncBunsiness(idBussines, param = null, second_param = null) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/sync_business/${idBussines}/`;
      if (param) {
        query += `?${param}=true`;
      }
      if (second_param) {
        query += `&${second_param}=true`;
      }
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  syncBunsinessPhotos(idBussines) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/sync_business_photos/${idBussines}/`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  syncBunsinessVideos(idBussines) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/sync_business_videos/${idBussines}/`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  updateBunsiness(idBussines) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/update_business/${idBussines}/`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getCategoriesPhoto(idBussines, isBatch = false) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/google_photo_category/${idBussines}/`;
      if (isBatch) {
        query += "?batch_update=True";
      }
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  setLocalMarketing(section, data) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/local_marketing/`;
      query += `?center=${Stores.getters.getNode}`;
      query += `&tree=${Stores.getters.getTreeSelected.id}`;
      query += `&section=${section}`;
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .put(query, data)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  uploadLocalMarketingPhoto(idBussines, bodyFormData, category) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/center_imagen_upload/${idBussines}/`;
      let token = SrvCommon.getToken();
      // llamada a la API
      let formData = new FormData();
      formData.append("file", bodyFormData);
      formData.append("file_category", category);
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .post(query, formData, {"Content-Type": "multipart/form-data"})
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  uploadLocalMarketingVideo(idBussines, bodyFormData, category) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/center_video_upload/${idBussines}/`;
      let token = SrvCommon.getToken();
      // llamada a la API
      let formData = new FormData();
      formData.append("video", bodyFormData);
      formData.append("video_category", category);
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .post(query, formData, {"Content-Type": "multipart/form-data"})
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getHasToken() {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/google_token/`;

      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `?node=${Stores.getters.getNode}`;
        }
      }

      query += `&tree=${Stores.getters.getTreeSelected.id}`;

      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getValidateToken(tokenCode, tokenStatus) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/google_token_validate/`;

      let nodeForGoogle = window.localStorage.getItem("nodeForGoogle");

      if (nodeForGoogle) {
        query += nodeForGoogle;
      }

      query += `&tree=${Stores.getters.getTreeSelected.id}`;
      query += `&token_code=${tokenCode}`;
      query += `&token_status=${tokenStatus}`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getGoogleUserLocations(locationCode) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/google_user_locations/`;

      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `?node=${Stores.getters.getNode}`;
        }
      }

      query += `&tree=${Stores.getters.getTreeSelected.id}&list_locations=true`;

      if (locationCode) {
        query += `&account_name=${locationCode}`;
      }

      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  linkGoogle(center, google_name) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/link_center_google/`;

      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `?node=${Stores.getters.getNode}`;
        }
      }

      query += `&tree=${Stores.getters.getTreeSelected.id}`;

      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .post(query, {center, google_name})
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  /**
   * { center, access_token, user_token, page_id, tree }
   * @returns {Promise<unknown>}
   */
  linkFacebook(items) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/facebook_token/`;

      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `?center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `?node=${Stores.getters.getNode}`;
        }
      }

      query += `&tree=${Stores.getters.getTreeSelected.id}`;

      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .post(query, items)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  setUnlinkCenterGoogle(center) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/unlink_center_google/?center=${center}`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getBussinesExtraHoursType() {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);

      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get("business_extra_hours_type/")
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getNotifications(idBussines) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/center/${idBussines}/notifications/`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  setNotifications(idBussines, data) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/center/${idBussines}/notifications/`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .post(query, data)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  setUpdateInfo(data) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/local_marketing/batch_update/`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .post(query, data)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getLocationSectionFields(idBussines) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/center/${idBussines}/location_section_fields/`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  setStatusLocationSectionFields(idBussines, data) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.PENDING);
      let query = `/center/${idBussines}/location_section_fields/`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .put(query, data)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getNotificationStats() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/notification_stats/`;
      let method = "get";
      let token = SrvCommon.getToken();
      const params = new URLSearchParams();
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          params.append("center", Stores.getters.getNode);
        }
      } else {
        if (Stores.getters.getNode) {
          params.append("node", Stores.getters.getNode);
        }
      }
      params.append("tree", Stores.getters.getTreeSelected.id);
      query += "?" + params.toString();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios[method](query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  getTaskStatus(ordering) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/batch_update_manager/`;
      let method = "get";
      let token = SrvCommon.getToken();
      if (Stores.getters.getNode) {
        query += `?node=${Stores.getters.getNode}`;
      }
      if (ordering) {
        query += `&ordering=${ordering}`;
      }
      query += `&tree=${Stores.getters.getTreeSelected.id}`;
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios[method](query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  setMultipleBatchUpdate(data) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/local_marketing/batch_update/`;
      let method = "post";
      let token = SrvCommon.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios[method](query, data)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
  downloadXlsFile(query_params) {
    return new Promise((resolve, reject) => {
      Stores.commit(types.LOCAL_PENDING, true);
      let query = `/performance_stats_v2/`;
      let token = SrvCommon.getToken();
      axios.defaults.headers.common["Authorization"] = "Token " + token;

      query += `?tree=${Stores.getters.getTreeSelected.id}`;
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      query += `&before=${query_params.endDate}&after=${query_params.startDate}&format=xlsx`;

      let configHeaders = {};
      configHeaders = SrvCommon.getConfigHeadersExcel();

      axios
        .get(query, configHeaders)
        .then((res) => {
          Stores.commit(types.LOCAL_PENDING, false);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.LOCAL_PENDING, false);
          const status = error.response.status;
          if (status === 401) {
            SrvCommon.logout();
          }
          reject(error);
        });
    });
  },
};
