<template lang="pug">
  div
    div.margin-bottom-0.gridFull
      v-row.headTitleGrid.fixed-row(ref="filterContainerRef" :style="`min-width: ${containerWidth}px`")
      v-col.justify-space-between.d-flex(md="12")
        span.titleGrid #[translate Keywords ranking]
        span.downloadExcel.mr-5(@click="downloadExcelCustom()")
          img(:src="imgCommons.download" width="10")
      v-col.justify-start.d-flex(md="12")
        div.filters
          span.labelFilter #[translate Categories]:
            div.filter.ml-2.mr-4
            filter-tree-menu(:options="opinionsCategories" label-class="text-capitalize-first-letter" :itemsChecked="opinionsCategoriesSelected" @checked="onCheckOpinionsCategories" id-key="id" label-key="name")
      v-row.no-gutters(ref="contentContainerRef" :style="`margin-top: ${filterContainerHeight}px`")
        .card-content.col-12
          .row.text-left
            v-col.col-6(v-if="data && data.positives && data.positives.length > 0")
              .tableGlobal
                div.headerTableRanking.row.ma-0.py-1
                  div.headerItem.col-top5
                    p.mb-0 #[span #] #[translate In positive opinions]
                  div.headerItem.col-nombre
                    p.mb-0.text-left #[translate Word]
                  div.headerItem.col-valoracion
                    p.mb-0 #[translate No. of times]
                  div.headerItem.col-tipo
                    p.mb-0.text-left #[translate Type]
                  div.headerItem.col-categoria
                    p.mb-0.text-left #[translate Category]

                div.contentTableRanking
                  p.ml-3(v-if="!data") #[translate No data]
                  div.itemContent.col-12.pa-0.hover-resize(v-for="(item, index) in data.positives" :key="index")
                    div.item.col-top5
                      span.ml-6 {{ index + 1 }}
                    div.item.col-nombre
                      p.mb-0.text-left.word {{ item.word }}
                    div.item.col-valoracion
                      p.mb-0.ml-1 {{ item.repetitions | formatNumber }}
                    div.item.col-tipo
                      p.mb-0.text-left
                        img(v-if="item.type === 'delivery'" :src="img.delivery")
                        img(v-else :src="img.local")
                    div.item.col-categoria
                      p.mb-0.text-left.items-center.d-flex
                        tag-internal(:category="{...item, name: item.category_name}" :hasLink="true" :has-edit-tags="false")

            v-col.col-6(v-if="data && data.negatives && data.negatives.length > 0")
              .tableGlobal
                div.headerTableRanking.row.ma-0.py-1
                  div.headerItem.col-bottom5
                    p.mb-0 #[span #] #[translate In negative opinions]
                  div.headerItem.col-nombre
                    p.mb-0.text-left #[translate Word]
                  div.headerItem.col-valoracion
                    p.mb-0 #[translate No. of times]
                  div.headerItem.col-tipo
                    p.mb-0.text-left #[translate Type]
                  div.headerItem.col-categoria
                    p.mb-0.text-left #[translate Category]

                div.contentTableRanking
                  p.ml-3(v-if="!data") #[translate No data]
                  div.itemContent.col-12.pa-0.hover-resize(v-for="(item, index) in data.negatives" :key="index")
                    div.item.col-bottom5
                      span.ml-6 {{ index + 1 }}
                    div.item.col-nombre
                      p.mb-0.text-left.word {{ item.word }}
                    div.item.col-valoracion
                      p.mb-0.ml-1 {{item.repetitions | formatNumber}}
                    div.item.col-tipo
                      p.mb-0.text-left
                        img(v-if="item.type === 'delivery'" :src="img.delivery")
                        img(v-else :src="img.local")
                    div.item.col-categoria
                      p.mb-0.text-left.items-center.d-flex
                        tag-internal(:category="{...item, name: item.category_name}" :has-edit-tags="false")
            hr.mt-8.mb-8.separatorBox(v-if="nCajas > 2")
            v-col.col-6(v-if="data && data.neutrals && data.neutrals.length > 0")
              .tableGlobal
                div.headerTableRanking.row.ma-0.py-1
                  div.headerItem.col-neutrals
                    p.mb-0 #[span #] #[translate In neutral opinions]
                  div.headerItem.col-nombre
                    p.mb-0.text-left #[translate Word]
                  div.headerItem.col-valoracion
                    p.mb-0 #[translate No. of times]
                  div.headerItem.col-tipo
                    p.mb-0.text-left #[translate Type]
                  div.headerItem.col-categoria
                    p.mb-0.text-left #[translate Category]

                div.contentTableRanking
                  p.ml-3(v-if="!data") #[translate No data]
                  div.itemContent.col-12.pa-0.hover-resize(v-for="(item, index) in data.neutrals" :key="index")
                    div.item.col-neutrals
                      span.ml-6 {{ index + 1 }}
                    div.item.col-nombre
                      p.mb-0.text-left.word {{ item.word }}
                    div.item.col-valoracion
                      p.mb-0.ml-1 {{ item.repetitions | formatNumber }}
                    div.item.col-tipo
                      p.mb-0.text-left
                        img(v-if="item.type === 'delivery'" :src="img.delivery")
                        img(v-else :src="img.local")
                    div.item.col-categoria
                      p.mb-0.text-left.items-center.d-flex
                        tag-internal(:category="{...item, name: item.category_name}" :has-edit-tags="false")

            v-col.col-6(v-if="data && data.notrank && data.notrank.length > 0")
              .tableGlobal
                div.headerTableRanking.row.ma-0
                  div.headerItem.col-norank
                    p.mb-0 #[span #] #[translate In unmarked opinions]
                  div.headerItem.col-nombre
                    p.mb-0.text-left #[translate Word]
                  div.headerItem.col-valoracion
                    p.mb-0 #[translate No. of times]
                  div.headerItem.col-tipo
                    p.mb-0.text-left #[translate Type]
                  div.headerItem.col-categoria
                    p.mb-0.text-left #[translate Category]

                div.contentTableRanking.row.ma-0
                  p.ml-3(v-if="!data") #[translate No data]
                  div.itemContent.col-12.pa-0(v-for="(item, index) in data.notrank" :key="index")
                    div.item.col-norank
                      span.ml-6 {{ index + 1 }}
                    div.item.col-nombre
                      p.mb-0.text-left.word {{ item.word }}
                    div.item.col-valoracion
                      p.mb-0.ml-1 {{item.repetitions | formatNumber}}
                    div.item.col-tipo
                      p.mb-0.text-left
                        img(v-if="item.type === 'delivery'" :src="img.delivery")
                        img(v-else :src="img.local")
                    div.item.col-categoria
                      p.mb-0.text-left
                        tag-internal(:category="{...item, name: item.category_name}" :has-edit-tags="false")
</template>

<script>
import SrvFeedback from '@/services/feedback.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import FilterTreeMenu from "@/components/filters/filterTreeMenu"
import TagInternal from '@/components/common/TagInternal.vue'

export default {
  mixins: [commonMixins],
  components: {TagInternal, FilterTreeMenu}, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  data() {
    return {
      img: {
        delivery: require('@/assets/img/ico-delivery.svg'),
        local: require('@/assets/img/ico-local.svg')
      },
      loading: true, // BOOLEANA PARA MOSTRAR LOADING EN LA TABLE
      data: null,
      containerWidth: 0,
      filterContainerHeight: 0,
    }
  },
  // OBTENERMOS DEL STORE LOS FILTROS APLICADOS
  async created() {
    if (this.$store.getters.getNode > 0) {
      this.getKeywords()
    }
  },
  mounted() {
    this.containerWidth = this.$refs.contentContainerRef.clientWidth
    this.filterContainerHeight = this.$refs.filterContainerRef.clientHeight

    this.observer = new ResizeObserver((entry) => {
      if (entry.length) {
        const [el] = entry
        this.containerWidth = el.contentRect.width
      }
    })
    this.observer?.observe(this.$refs.contentContainerRef)
  },
  watch: {
    filterApply: function () {
      this.getKeywords()
    }
  },
  computed: {
    nCajas() {
      let total = 0
      if (this.data && this.data.positives && this.data.positives.length > 0) {
        total++
      }
      if (this.data && this.data.negatives && this.data.negatives.length > 0) {
        total++
      }
      if (this.data && this.data.neutrals && this.data.neutrals.length > 0) {
        total++
      }
      if (this.data && this.data.notrank && this.data.notrank.length > 0) {
        total++
      }
      return total
    },
    opinionsCategories() {
      let categoriesCrud = []
      let selectedCat = this.$store.getters.getTypesSelected ? this.$store.getters.getTypesSelected : 'internet'

      if (this.$store.getters.getCategories[selectedCat]) {
        categoriesCrud = this.$store.getters.getCategories[selectedCat].categories
        categoriesCrud = categoriesCrud.map((c) => {
          // BORRAMOS SUBCATEGORIAS PARA PANTALLA KEYWORDS
          c.children = []
          return c
        })
      }

      return categoriesCrud
    },
    opinionsCategoriesSelected() {
      return this.$store.getters.getOpinionsCategoriesSelected.map(c => c.value)
    }
  },
  methods: {
    downloadExcelCustom() {
      let val = this.$store.getters.getOpinionsCategoriesSelected
      let type = ''
      if (val.length > 0) {
        type = val[0].type
      }
      let params = {type: type}
      this.downloadExcel(params)
    },
    // FUNIONES LLAMADAS DESDE EL COMPONENTE MULTISELECT CON EMMIT
    onCheckOpinionsCategories(val) {
      this.$store.commit(types.FEEDBACK_FILTER_OPINIONS_CATEGORIES_SELECTED, val)
      let type = ''
      if (val.length > 0) {
        type = val[0].type
      }
      this.getKeywords(type)
    },
    getKeywords(type = '') {
      SrvFeedback.getKeywords(type)
        .then(res => {
          if (res.status === 200) {
            this.data = res.data
          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed-row {
  position: fixed;
  background: #fff;
}

.bgRed {
  background-color: #D61A23;
}

.bgOrange {
  background-color: #FCAE19;
}

.bgGreen {
  background-color: #89C445;
}

.word {
  font-weight: 600;
}

.separatorBox {
  clear: both;
  display: block;
  width: 94%;
  margin: 0 auto;
}

.tableGlobal {

  .col-top5 {
    color: #417505;
  }

  .col-bottom5 {
    color: #D0021B;
  }

  .col-neutrals {
    color: #F5A623;
  }

  .col-bottom5,
  .col-top5,
  .col-neutrals,
  .col-norank {
    max-width: 16.33%;
    flex: 0 0 16.33%;
    margin-right: 2.5%;
    padding-left: 10px;
    text-align: left;
    letter-spacing: 0.012px;
  }

  .col-valoracion {
    max-width: 22.47%;
    flex: 0 0 22.47%;
    margin-right: 4.5%;
    text-align: center;

    .box {
      width: 50px;
      border-radius: 5px;
      color: #fff;
    }
  }

  .col-tipo {
    max-width: 10.47%;
    flex: 0 0 10.47%;
    margin-right: 4.5%;
    text-align: left;
  }

  .col-categoria {
    max-width: 21.47%;
    flex: 0 0 21.47%;
    text-align: left;
  }

  .col-nombre {
    max-width: 16.47%;
    flex: 0 0 16.47%;
    text-align: left;
    // color:#4A90E2;
  }

  .headerTableRanking {
    .headerItem {
      text-align: right;
      font-size: 0.75rem;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: right;
      color: rgba(22, 22, 22, 0.8);

      &.col-tipo {
        text-align: left;
        justify-content: initial;
      }

      &.col-categoria {
        text-align: left;
        justify-content: initial;
      }

      &.col-nombre {
        text-align: left;
        justify-content: initial;
      }

      &.col-valoracion {
        text-align: center;
        justify-content: center;
      }

      &.col-top5 {
        color: #89C445;
        text-align: left;
        justify-content: initial;
      }

      &.col-bottom5 {
        color: #D0021B;
        text-align: left;
        justify-content: initial;
      }

      &.col-neutrals {
        color: #F5A623;
        text-align: left;
        justify-content: initial;
      }

      &.col-norank {
        text-align: left;
        justify-content: initial;
      }

      img {
        cursor: pointer;
      }
    }
  }

  .contentTableRanking {
    .itemContent {
      text-align: center;
      font-size: 0.875rem;
      border-top: 1px solid #9797973d;
      height: 41px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
