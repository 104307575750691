<template lang="pug">
  div
    div.margin-bottom-0.gridFull
      v-row.headTitleGrid(style="position: relative;")

        v-col(md="4")
          div.tsm-tabs
            div(v-for="item in tabs")
              div.tsm-tab(@mousedown="activateTab(item)" :class="[{ 'active': item.active }, item.type]" )
                div {{ item.title }}

        v-col(md="8")
          div.filters
            span.downloadExcel.mr-5(@click="downloadExcelCustom()")
              img(:src="imgCommons.download" width="10")
            span.labelFilter #[translate Search]:
              div.filter.ml-2.mr-4
                input.filterBySearch(type="text" v-model="search")
                button.filterBySearchButton.clean(@click="clean" v-if="search")
                  img(:src="img.clean")
                button.filterBySearchButton(@click="getDishRanking")
                  img(:src="img.search")

      v-row(v-if="!data || !data[currentTab.type] || data[currentTab.type].length === 0")

        v-col(v-if="loading" md="4" style="padding: 3rem;")
          span Cargando...
        v-col.col-12.justify-center.d-flex(v-else style="padding: 3rem;")
          span No hay data


      v-row.mt-0#headerEmployees(v-if="data && data[currentTab.type] && data[currentTab.type].length")
        .row.text-left
          v-col.col-12.tablaGR
            .container
              .row
                .col-3
                  span # #[translate PLATO]
                  .boxOrder
                    span.btnOrder(@click="setOrderEmployeesAsc('name')")
                      img(:src="img.sort")
                    span.btnOrder(@click="setOrderEmployeesDesc('name')")
                      img(:src="img.sortActive")
                .col-8
                  span #[translate No. DE VECES]
                  .boxOrder
                    span.btnOrder(@click="setOrderEmployeesAsc('n_reviews')")
                      img(:src="img.sort")
                    span.btnOrder(@click="setOrderEmployeesDesc('n_reviews')")
                      img(:src="img.sortActive")
      v-row.contentOutPadding.mt-0#boxTableComments(v-if="data && data[currentTab.type] && data[currentTab.type].length")
        .card-content
        .row.text-left.mt-0
          v-col.col-12.tablaGR.pt-0.pb-0(v-if="data")
            .container
              v-expansion-panels(v-model="panelActive")
                .row(v-for="(item, index) in data[currentTab['type']]")
                  v-expansion-panel(
                    :key="index" @click="getDishReviews(item)")
                    v-expansion-panel-header
                      .col-3.nameEmploye
                        span.posEmployee {{index + 1}}
                        span {{item.name}}
                      .col-8
                        .boxNumberReviews
                          span {{item[`${currentTab['type'].slice(0, -1)}_count`]}}
                    v-expansion-panel-content
                      table-opinions(:comments="commentsLoad" :loading="loading"  @linkFilterCategory="linkFilterByCategory" @setOrder="setOrder" :pagination="true")
</template>

<script>
import SrvFeedback from '@/services/feedback.js'
import * as types from '@/store/mutation-types'
import commonMixins from "@/mixins/common.js"
import TableOpinions from "@/components/global/TableOpinions"

export default {
  mixins: [commonMixins],
  components: {TableOpinions}, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  data() {
    return {
      tabs: [
        {title: 'Platos más valorados', type: 'favourites', active: true},
        {title: 'Platos menos valorados', type: 'unfavourites', active: false},
      ],
      currentTab: undefined,
      search: '',
      loading: true, // BOOLEANA PARA MOSTRAR LOADING EN LA TABLE
      opinionsTypes: [ // ELEMENTOS FILTRO POR TIPO DE COMENTARIO
        {value: 'with_text', text: this.$gettext('With comments')},
        {value: 'positives', text: this.$gettext('Positives')},
        {value: 'neutrals', text: this.$gettext('Neutral')},
        {value: 'negatives', text: this.$gettext('Negatives')},
        {value: 'with_reply', text: this.$gettext('With reply')},
        {value: 'without_reply', text: this.$gettext('Without reply')}
      ],
      panelActive: [],
      ordering: '-source_date',
      orderingEmployees: '-n_reviews',
      opinionsTypesSelected: [], // ELEMENTOS SELECCIONADOS DEL FILTRO
      commentsLoad: [],
      rankingActive: null,
      data: null,
      img: {
        search: require('@/assets/img/buscar.svg'),
        clean: require('@/assets/img/borrar.svg'),
        sort: require('@/assets/img/mini_arrow_shack_disable.svg'),
        sortActive: require('@/assets/img/mini_arrow_shack_active.svg')
      }
    }
  },
  // OBTENERMOS DEL STORE LOS FILTROS APLICADOS
  async created() {
    this.opinionsSitesSelected = this.$store.getters.getOpinionsSitesSelected
    if (this.$store.getters.getNode > 0) {
      this.getDishRanking()
    }
    this.getDishRanking()
    this.currentTab = this.tabs[0]
  },
  watch: {
    filterApply: function () {
      this.getDishRanking()
    },
    ordering: function () {
      if (this.rankingActive) {
        this.getDishReviews(this.rankingActive)
      }
    },
    orderingEmployees: function () {
      this.commentsLoad = []
      this.rankingActive = null
      this.panelActive = []
      this.getDishRanking()
    }
  },
  computed: {
    countEmployees() {
      if (this.data) {
        return this.data.length
      }
      return 0
    },
    opinionsCategories() {
      let categoriesCrud = []
      let selectedCat = this.$store.getters.getTypesSelected ? this.$store.getters.getTypesSelected : 'internet'

      if (this.$store.getters.getCategories[selectedCat]) {
        categoriesCrud = this.$store.getters.getCategories[selectedCat].categories
        categoriesCrud = categoriesCrud.map((c) => {
          // BORRAMOS SUBCATEGORIAS PARA PANTALLA KEYWORDS
          c.children = []
          return c
        })
      }
      return categoriesCrud
    },
    opinionsCategoriesSelected() {
      return this.$store.getters.getOpinionsCategoriesSelected.map(c => c.value)
    },
    opinionsTypesSelectedMaped() {
      return this.opinionsTypesSelected.map(c => c.value)
    }
  },
  methods: {
    activateTab(tab) {
      this.currentTab.active = false
      tab.active = true
      this.currentTab = tab
      if (this.rankingActive) {
        this.getDishReviews(this.rankingActive)
      }
    },
    clean() {
      this.search = ''
      this.getDishRanking()
    },
    setOrderEmployeesAsc(val) {
      this.orderingEmployees = '-' + val
    },
    setOrderEmployeesDesc(val) {
      this.orderingEmployees = val
    },
    setOrder(val) {
      this.ordering = val
    },
    linkFilterByCategory(category) {
      this.onCheckOpinionsCategories([category])
    },
    downloadExcelCustom() {
      let val = this.$store.getters.getOpinionsCategoriesSelected
      let type = ''
      if (val.length > 0) {
        type = val[0].type
      }
      let params = {type: type}
      this.downloadExcel(params)
    },
    // FUNIONES LLAMADAS DESDE EL COMPONENTE MULTISELECT CON EMMIT
    onCheckOpinionsTypes(val) {
      this.$store.commit(types.FEEDBACK_FILTER_OPINIONS_TYPES_SELECTED, val)
      this.getDishRanking()
      if (this.rankingActive) {
        this.getDishReviews(this.rankingActive)
      }
    },
    onCheckOpinionsCategories(val) {
      this.$store.commit(types.FEEDBACK_FILTER_OPINIONS_CATEGORIES_SELECTED, val)
      this.getDishRanking()
      if (this.rankingActive) {
        this.getDishReviews(this.rankingActive)
      }
    },
    getDishRanking() {
      this.loading = true
      SrvFeedback.getDishRanking(this.search)
        .then(res => {
          if (res.status === 200) {
            this.data = res.data
          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        }).finally(() => {
        this.loading = false
      })
    },
    getDishReviews: function (ranking) {
      this.rankingActive = ranking
      this.loading = true
      this.commentsLoad = []

      SrvFeedback.getReviewsDishRanking({id: ranking['id'], type: this.currentTab.type, ordering: this.ordering})
        .then(res => {
          if (res.status === 200) {
            let custom = res.data.results.map((obj, index) => ({
              ...obj['review'],
              ['comentariocompleto']: false,
              ['showReply']: false,
              ['showByPlugin']: false,
              ['unsavedReply']: '',
              ['idGenerate']: obj.id + '-' + index
            }))

            this.commentsLoad = custom
            this.$store.commit(types.FEEDBACK_OPINIONS, {results: custom})
            this.loading = false
          }
        }).catch(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.tsm-tabs {
  display: flex;
  position: absolute;
  bottom: -10px;
  left: 0;

  .tsm-tab {
    cursor: pointer;
    padding: 0.3rem 2rem;
    display: flex;
    border-bottom: solid 2px transparent;
    font-size: 14px;

    &.active {
      border-bottom-color: #F93549;
      cursor: default;
    }

    &.favourites {
      color: #91D844;
    }

    &.unfavourites {
      color: #F93549;
    }
  }
}

.gridFull {
  overflow: hidden;
}

.filterBySearch {
  vertical-align: middle;
  font-size: 0.75rem;
  border: 1px solid #D7DAE2;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0;
  background: white;
  color: #1976d2;
  height: 34px;
  padding-left: 16px;
}

.filterBySearchButton {
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #D7DAE2;
  border-left: 0;
  height: 34px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 10px;

  &.clean {
    border-right: 1px solid #D7DAE2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  img {
    width: 14px;
    margin-top: 5px;
  }
}

.boxOrder {
  margin-top: 4px;
  display: inline-block;
  margin-left: 15px;

  .btnOrder {
    display: block;
    line-height: 0;
    cursor: pointer;

  }
}

.v-expansion-panels {
  display: block;

  .row {
    margin-bottom: 0;
    margin-top: 0;
    border-bottom: 2px solid #ccc;

  }
}

.v-expansion-panel {
  &:hover {
    background-color: #F2F2F2;
  }

  &:before {
    box-shadow: none;
  }
}

.theme--light.v-expansion-panels .v-expansion-panel {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;

}

.container {
  max-width: 100%;
  width: 100%;
}

.v-expansion-panel-header {
  min-height: 0;
  padding: 0;
}

#headerEmployees {
  padding: 0 1px;
  font-size: 0.75rem;

  .container {
    background-color: #F2F2F2;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
  }
}

.nameEmploye {
  border-right: 1px solid #ccc;
  color: #43425D;
  font-size: 0.875rem;
  font-weight: 600;
}

.posEmployee {
  display: inline-block;
  min-width: 30px;
  font-weight: 400;
}

.boxNumberReviews {
  min-width: 80px;
  text-align: right;
  display: inline-block;
}
</style>
