<template lang="pug">
  div.margin-bottom-0(:class="col")
    skeleton-card(v-if="isLoading")
    v-card.gridFull.ma-0(v-if="!isLoading && dataSummary.length" :class="{'borderDelivery': typeOpinionSelected === 'delivery'}")
      .d-flex.headTitleGrid
        .block-title.titleGrid
          translate.mb-0.title-card {{title }}
          span.ml-1.tDelivery(v-if="typeOpinionSelected === 'delivery'") Delivery
        .block-help.ml-auto
          v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="20" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
            template(v-slot:activator="{ on, attrs }")
              img(:src="imgCommons.help" v-bind="attrs" v-on="on")
            span {{helpFeeling}}

      .card-content
        .row.text-left
          #tableKantar.tableGlobal
            div.headerTableRanking.row.ma-0
              div.headerItem.col-name
                p
              div.headerItem.col-quiz
                p.mb-0 #[translate Quiz]

            div.contentTableRanking.row.ma-0
              p.ml-3(v-if="!dataSummary") #[translate No data]
              div.itemContent.col-12.pa-0.hover-resize(v-for="(item, index) in dataSummary" :key="index")
                div.item.col-name.text-left.pl-2(:class="getTextBlackIfGroup(item)")
                  v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                    template(v-slot:activator="{ on, attrs }")
                      span.ellipsis1Col(v-if="index == 0" v-bind="attrs" v-on="on") {{item.name}}
                      span.link-text.ellipsis1Col(v-if="index>0" @click="goItemNextLevel(item)" v-bind="attrs" v-on="on") {{item.name}}
                    span(v-if="index == 0") {{item.name}}
                    span(v-if="index>0" ) {{item.name}}
                div.item.col-quiz.text-right
                  span.pr-4 {{item.total | formatNumber}}

</template>

<script>
import commonMixins from "@/mixins/common.js"
import SkeletonCard from '@/components/common/skeletonCard.vue'

export default {
  props: {
    'dataSummary': [],
    'title': {
      type: String,
      required: true,
    },
    'col': {
      type: String,
      required: false,
      default: '.col-3'
    }
  },
  mixins: [commonMixins],
  components: {SkeletonCard},
  data() {
    return {
      helpFeeling: this.$gettext('From the semantic analysis of opinions, we obtain the sentiment data and its trend compared to the same selected period of the previous year.')
    }
  }
}
</script>

<style lang="scss" scoped>
#tableKantar {
  .col-name {
    max-width: 43.33%;
    flex: 0 0 43.33%;
    padding-left: 10px;
    text-align: left;

    &.media-cadena {
      color: #979797;

      span {
        letter-spacing: -0.02px;
      }
    }
  }

  .col-quiz {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-satisfaccion {
    max-width: 28.33%;
    flex: 0 0 28.33%;
    margin-right: 2%;
    justify-content: center;
  }
}
</style>
