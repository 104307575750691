<template lang="pug">
  div
    v-dialog.dialogEdit(v-model="show"
      width="680")
      v-card.dialogEdit
        v-card-text.pb-0
        v-container
          div.gr-loader-snipper--wrapper(v-if="loading")
            span.gr-loader-snipper
          v-row#headerModalEdit.pt-3
            v-col(cols="10")
              span #[translate Social media]
            v-col.mt-5(cols="2")
              img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
          v-row#bodyModalEdit
            v-col(cols="12")
              v-container
                v-row
                  v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                    div.messageError
                      span {{ messageError ? messageError : $gettext('An error occurred while recording.') }}
                v-row.mt-5.mb-5
                  v-col.text-left.pb-0(cols="1")
                    img(:src="img.web")
                  v-col.text-left.pb-0(cols="11")
                    span.d-block #[translate URL web]
                    input.inputFullEditModal(type="text" v-model="data.url")
                    a(v-if="data.url" :href="data.url" target="_blank")
                      img.ml-4(:src="img.link")
                v-row.mt-5.mb-5
                  v-col.text-left.pb-0(cols="1")
                    img(:src="img.bing")
                  v-col.text-left.pb-0(cols="11" v-if="data.socialaccounts")
                    span.d-block #[translate URL web]
                    input.inputFullEditModal(type="text" v-model="data.socialaccounts.bing_maps_url" @change="enableConfirmationMessage=true")
                    a(v-if="data.socialaccounts.bing_maps_url" :href="data.socialaccounts.bing_maps_url" target="_blank")
                      img.ml-4(:src="img.link")
                v-row.mb-5
                  v-col.text-left.pb-0(cols="1")
                    img(:src="img.facebook")
                  v-col.text-left.pb-0(cols="11" v-if="data.socialaccounts")
                    span.d-block #[translate URL Facebook]
                    input.inputFullEditModal(type="text" v-model="data.socialaccounts.facebook_url")
                    a(v-if="data.socialaccounts.facebook_url" :href="data.socialaccounts.facebook_url" target="_blank")
                      img.ml-4(:src="img.link")
                v-row.mb-5
                  v-col.text-left.pb-0(cols="1")
                    img(:src="img.instagram")
                  v-col.text-left.pb-0(cols="11" v-if="data.socialaccounts")
                    span.d-block #[translate URL Instagram]
                    input.inputFullEditModal(type="text" v-model="data.socialaccounts.instagram_url")
                    a(v-if="data.socialaccounts.instagram_url" :href="data.socialaccounts.instagram_url" target="_blank")
                      img.ml-4(:src="img.link")
                v-row.mb-5
                  v-col.text-left.pb-0(cols="1")
                    img(:src="img.twitter")
                  v-col.text-left.pb-0(cols="11" v-if="data.socialaccounts")
                    span.d-block #[translate URL Twitter]
                    input.inputFullEditModal(type="text" v-model="data.socialaccounts.twitter_url")
                    a(v-if="data.socialaccounts.twitter_url" :href="data.socialaccounts.twitter_url" target="_blank")
                      img.ml-4(:src="img.link")
                v-row.mb-5
                  v-col.text-left.pb-0(cols="1")
                    img(:src="img. youtube")
                  v-col.text-left.pb-0(cols="11" v-if="data.socialaccounts")
                    span.d-block #[translate URL Youtube]
                    input.inputFullEditModal(type="text" v-model="data.socialaccounts.youtube_url")
                    a(v-if="data.socialaccounts.youtube_url" :href="data.socialaccounts.youtube_url" target="_blank")
                      img.ml-4(:src="img.link")
                v-row.mb-5
                  v-col.text-left.pb-0(cols="1")
                    img(:src="img.linkedin")
                  v-col.text-left.pb-0(cols="11" v-if="data.socialaccounts")
                    span.d-block URL Linkedin
                    input.inputFullEditModal(type="text" v-model="data.socialaccounts.linkedin_url")
                    a(v-if="data.socialaccounts.linkedin_url" :href="data.socialaccounts.linkedin_url" target="_blank")
                      img.ml-4(:src="img.link")
                v-row.mb-5
                  v-col.text-left.pb-0(cols="1")
                    img(:src="img.tiktok")
                  v-col.text-left.pb-0(cols="11" v-if="data.socialaccounts")
                    span.d-block URL TikTok
                    input.inputFullEditModal(type="text" v-model="data.socialaccounts.tiktok_url")
                    a(v-if="data.socialaccounts.tiktok_url" :href="data.socialaccounts.tiktok_url" target="_blank")
                      img.ml-4(:src="img.link")
                v-row.mb-5(v-if="!isAdverbia")
                  v-col.text-left.pb-0(cols="1")
                    img(:src="img.carta")
                  v-col.text-left.pb-0(cols="11" v-if="data.socialaccounts")
                    span.d-block #[translate Letter url]
                    input.inputFullEditModal(type="text" v-model="data.menu_url")
                    a(v-if="data.menu_url" :href="data.menu_url" target="_blank")
                      img.ml-4(:src="img.link")
                v-row.mb-5
                  v-col.text-left.pb-0(cols="1")
                    img(:src="img.reservas")
                  v-col.text-left.pb-0(cols="11" v-if="data.socialaccounts")
                    span.d-block #[translate Booking engine URL]
                    input.inputFullEditModal(type="text" v-model="data.booking_url")
                    a(v-if="data.booking_url" :href="data.booking_url" target="_blank")
                      img.ml-4(:src="img.link")

          v-row#footerModalEdit
            v-col(cols="12")
              button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
              button.btnSaveEditModal(@click="saveAction")  #[translate Save]
    warning-state-custom(:visible="showConfirmationMessage" @close="showConfirmationMessage=false" @confirmed="save()")
      p #[translate Por favor, asegúrese de que este negocio este verificado en BING para poder sincronizar los datos con la periodicidad que hayas indicado.]
</template>
<script>
import commonMixins from '@/mixins/common.js'
import SrvLocal from '@/services/local.js'
import warningStateCustom from './warningStateCustom.vue'

export default {
  mixins: [commonMixins],
  props: ['visible', 'idBussines'],
  components: {warningStateCustom},
  data() {
    return {
      loading: false,
      enableConfirmationMessage: false,
      showConfirmationMessage: false,
      imgClose: require('@/assets/img/ico-close-edit.svg'),
      img: {
        facebook: require('@/assets/img/facebook.svg'),
        web: require('@/assets/img/ico-web.svg'),
        reservas: require('@/assets/img/ico-reservas.svg'),
        carta: require('@/assets/img/ico-carta.svg'),
        youtube: require('@/assets/img/ico-youtube.svg'),
        twitter: require('@/assets/img/ico-twitter.svg'),
        instagram: require('@/assets/img/ico-instagram.svg'),
        link: require('@/assets/img/ico-external-link.svg'),
        bing: require('@/assets/img/local/BIN.svg'),
        linkedin: require('@/assets/img/ico-linkedin.svg'),
        tiktok: require('@/assets/img/ico-tiktok.svg')
      },
      name: '',
      data: {},
      section: 'social-media-restaurant',
      saveStatus: true,
      messageError: null
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.data = {}
        this.loadData()
      }
    },
  },
  methods: {
    saveAction() {
      this.enableConfirmationMessage ? this.showConfirmationMessage = true : this.save()
    },
    closeModals() {
      this.showConfirmationMessage = false
      this.enableConfirmationMessage = false
      this.show = false
    },
    async loadData() {
      //CARGAMOS DATOS
      this.loading = true
      await SrvLocal.getLocalMarketing(this.section)
        .then(res => {
          if (res.status === 200) {
            this.data = res.data
            this.loading = false
          }
        }, () => {
        })
    },
    async save() {
      this.loading = true
      await SrvLocal.setLocalMarketing(this.section, this.data)
        .then(res => {
          if (res.status !== 200) {
            this.saveStatus = false
          }
        }, () => {
        })

      if (this.saveStatus) {
        const res = await SrvLocal.syncBunsiness(this.idBussines, 'only_detail_info', 'only_attributes')
        if (Object.keys(res.data).includes('success') && !res.data.success) {
          this.saveStatus = false
          this.messageError = res.data.error.message
        } else {
          this.closeModals()
          this.$emit('refreshSync')
        }
      }

      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
#bodyModalEdit {
  .inputFullEditModal {
    width: 90%;
  }
}

.messageError {
  background-color: #F5B1B8;
  border: 1px solid #F93549;
  color: #F93549;
  padding: 15px;
}
</style>
