<template>
  <div :class="{ premiumDisabled: !hasPremium }">
    <div class="d-flex justify-end mb-2 items-center pt-2">
      <BreadCrumbs :borderBottom="false" class="flex-1"></BreadCrumbs>
      <RangeDatesByMonths class="mr-2" @rangeSelected="setRange" :range="$store.getters.getRangeDatesMetrics">
      </RangeDatesByMonths>
    </div>
    <skeleton-card v-if="isLoading"></skeleton-card>
    <div v-else-if="!isLoading && !notData">
      <!-- metris google -->
      <div class="boxMetricsBySite">
        <v-row class="pa-5 justify-space-between">
          <img :src="img.googleText"/>
          <span class="cursor-pointer" @click="downloadXlsFile">
            <img :src="imgCommons.download" width="15"/>
          </span>
        </v-row>
        <hr class="borderLogo"/>
        <p class="text-14 font-weight-bold mt-4">{{ $gettext("Plataformas y dispositivos que los usuarios han utilizado para buscar tu perfil.") }}</p>
        <div class="d-flex boxCharts">
          <div class="boxPersonas">
            <span class="font-weight-bold text-18 mr-1">{{ totalView | formatNumber }}</span>
            <span class="text-14">{{ $gettext("Personas que han visto tu Perfil de Empresa") }}</span>

            <div>
              <div class="d-flex align-center">
                <div class="col-3">
                  <div style="height: 147px">
                    <ChartDought :chartData="dataChartViews" height="147px"></ChartDought>
                  </div>
                </div>
                <div class="dataViews text-14">
                  <div v-for="(item, index) in viewsProfile" :key="index" class="item d-flex align-center"
                       :class="{ 'mb-5': viewsProfile.length - 1 > index }">
                    <span class="circle mr-3" :style="'background-color:' + item.color + ';'"></span>
                    <img :src="item.ico" class="mr-3"/>
                    <strong class="mr-3">{{ item.count | formatNumber(0, 0) }} - {{
                        parseFloat(item.porcentaje) |
                          formatNumber
                      }} %</strong>
                    <span> {{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="boxInteracciones w-[42%]">
            <span class="font-weight-bold text-18 mr-1">{{ totalInteractions | formatNumber }}</span>
            <span class="text-14">{{ $gettext("Interacciones sobre tu Perfil de Empresa") }}</span>
            <div style="height: 177px" class="mt-4">
              <BoxMetric height="147px" :title="titleChart" :total="totalChart" :data="dataMetrics" :legend="['asdas']">
              </BoxMetric>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <span class="font-weight-bold text-14">{{ $gettext("Acciones sobre tu perfil") }}</span>
          <div class="d-flex boxGrupoAcciones mt-3">
            <BoxActionInProfile class="boxAccio box-action-resize"
                                v-for="(action, index) in dataActionsGoogleFilteredByBrand.filter((e) => !e.hide)" :data="action"
                                :key="index"></BoxActionInProfile>
          </div>
        </div>
        <div class="boxSearchs mt-3 d-flex">
          <div v-if="typeNode === 'center'" class="searchRestaurant search d-flex">
            <span class="font-weight-bold text-14">{{ $gettext("Busquedas") }}</span>
            <div class="numberInResult d-flex text-14 justify-start mt-3">
              <div class="d-flex align-start mt-8">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.423" height="26.414" viewBox="0 0 26.423 26.414">
                  <g id="Xnix_Line_Search_5" data-name="Xnix/Line/Search 5" transform="translate(-3.991 -4)">
                    <path id="Vector"
                          d="M3.235,19.074A11.245,11.245,0,0,1,4.908,1.883a10.938,10.938,0,0,1,12.271,0A11.146,11.146,0,0,1,21.247,6.9a11.269,11.269,0,0,1-2.394,12.176,10.954,10.954,0,0,1-15.618,0Z"
                          transform="translate(5 5)" fill="none" stroke="#4885ed" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="2"/>
                    <path id="Vector-2" data-name="Vector"
                          d="M6.249,2.952A2.743,2.743,0,0,1,9.064,4.607m2.176,7.107,3.867,3.867"
                          transform="translate(13.893 13.419)" fill="none" stroke="#4885ed" stroke-linecap="round"
                          stroke-linejoin="round" stroke-width="2"/>
                  </g>
                </svg>
                <p class="ml-4">
                  <strong class="text-18">{{ dataSearchDesktop?.searchKeywords_total_count | formatNumber }}</strong>
                  Búsquedas que han mostrado tu Perfil de Empresa en<br/>
                  los resultados de búsqueda
                </p>
              </div>
            </div>
          </div>
          <div v-else class="search text-14 w-full">
            <span class="font-weight-bold text-14">{{ $gettext("Interacciones") }}</span>
            <div class="infoForRestaurant text-14 mt-3">
              <div class="pt-2 pb-3 d-flex justify-start">
                <span class="text-12">{{ $gettext("Negocios con más interacciones en su perfil") }}</span>
              </div>
              <v-row class="rowCenter hover-resize" v-for="(center, index) in interactions_ranking" :key="index">
                <v-col class="col-10 py-0">{{ index + 1 }}. {{ center.business_name }}</v-col>
                <v-col class="col-2 text-right py-0">
                  <strong>{{ center.total_count | formatNumber }}</strong>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-if="typeNode !== 'center'" class="searchRestaurant">
            <span v-if="!isGenericEntity" class="font-weight-bold text-14">{{
                $gettext("Restaurantes más buscados")
              }}</span>
            <span v-else class="font-weight-bold text-14">{{ $gettext("Negocios más buscados") }}</span>
            <div class="infoForRestaurant text-14 mt-3">
              <v-row class="rowCenter">
                <v-col class="col-4 py-0"></v-col>
                <v-col class="col-2 py-0 text-right">
                  <img src="@/assets/img/local/metrics/google/search-movil.svg"/>
                </v-col>
                <v-col class="col-2 py-0 text-right">
                  <img src="@/assets/img/local/metrics/google/search-maps.svg"/>
                </v-col>
                <v-col class="col-2 py-0 text-right">
                  <img src="@/assets/img/local/metrics/google/search-desktop.svg"/>
                </v-col>
                <v-col class="col-2 py-0 text-right">
                  <img src="@/assets/img/local/metrics/google/search-maps-desktop.svg"/>
                </v-col>
              </v-row>
              <v-row class="rowCenter hover-resize" v-for="(center, index) in dataViewsProfile[0].summary" :key="index">
                <v-col class="col-4 py-0">{{ index + 1 }}. {{ center.Business }}</v-col>
                <v-col class="col-2 text-right py-0">
                  <strong>{{
                      getNumberByMetricByType("BUSINESS_IMPRESSIONS_MOBILE_SEARCH", center.Business) |
                        formatNumber
                    }}</strong>
                </v-col>
                <v-col class="col-2 text-right py-0">
                  <strong>{{
                      getNumberByMetricByType("BUSINESS_IMPRESSIONS_MOBILE_MAPS", center.Business) |
                        formatNumber
                    }}</strong>
                </v-col>
                <v-col class="col-2 text-right py-0">
                  <strong>{{
                      getNumberByMetricByType("BUSINESS_IMPRESSIONS_DESKTOP_SEARCH", center.Business) |
                        formatNumber
                    }}</strong>
                </v-col>
                <v-col class="col-2 text-right py-0">
                  <strong>{{
                      getNumberByMetricByType("BUSINESS_IMPRESSIONS_DESKTOP_MAPS", center.Business) |
                        formatNumber
                    }}</strong>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-else class="searchRestaurant">
            <span class="font-weight-bold text-14">{{ $gettext("Términos de las búsquedas") }}</span>
            <div v-if="dataSearchDesktop && dataSearchDesktop.searchKeywordsCounts"
                 class="infoForRestaurant text-14 mt-3">
              <v-row class="rowCenter" :class="{ 'border-none': index === 0 }"
                     v-for="(center, index) in dataSearchDesktop.searchKeywordsCounts.slice(0, 5)" :key="index">
                <v-col class="col-10 py-0">{{ index + 1 }}. {{ center.searchKeyword }}</v-col>
                <v-col class="col-2 text-right py-0">
                  <strong>{{ (center.insightsValue.value || center.insightsValue.threshold) | formatNumber }}</strong>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div v-else-if="notData" class="boxMetricsBySite">
      <img :src="img.googleText"/>
      <hr class="borderLogo"/>
      <div class="d-flex justify-center align-center">
        <div class="my-8">
          <p class="text-center font-weight-bold">{{ $gettext('No se han encontrado datos') }}</p>
          <p class="d-flex justify-center align-center"><img src="@/assets/img/no_hay_datos.svg"/></p>
        </div>
      </div>


    </div>
  </div>
</template>
<script>
import SrvLocalMetrics from "@/services/localMetrics";
import SkeletonCard from "@/components/common/skeletonCard.vue";
import RangeDatesByMonths from "@/components/filters/RangeDatesByMonths.vue";
import ChartDought from "@/components/local/metrics/ChartDought.vue";
import commonMixin from "@/mixins/common.js";
import localMixin from "@/mixins/local.js";
import brandMixin from '@/mixins/brand.js'
import BoxMetric from "@/components/local/metrics/BoxChartMetric.vue";
import DataKeywords from "@/components/local/metrics/keywords.json";
import DataMetricsMock from "@/components/local/metrics/DataMetricsMock.json";
import BoxActionInProfile from "@/components/local/metrics/BoxActionInProfile.vue";
import BreadCrumbs from "@/components/common/Breadcrumbs.vue";
import * as types from '@/store/mutation-types'

export default {
  components: {BreadCrumbs, RangeDatesByMonths, SkeletonCard, BoxActionInProfile, ChartDought, BoxMetric},
  mixins: [commonMixin, localMixin, brandMixin],
  data() {
    return {
      img: {
        googleText: require("@/assets/img/google-text.svg"),
        facebookText: require("@/assets/img/facebook-text.svg"),
        instagramText: require("@/assets/img/instagram-text.svg"),
      },
      data: null,
      dataMockedFacebook: [
        {id: 'page_fan_adds', name: "Nº de Me gusta", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/nlikes.svg")},
        {id: 'page_actions_post_reactions_like_total', name: "Me gusta", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/megusta.png"), inter: true},
        {id: 'page_actions_post_reactions_love_total', name: "Me encanta", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/meencanta.png"), inter: true},
        {id: 'page_actions_post_reactions_wow_total', name: "Me asombra", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/measombra.png"), inter: true},
        {id: 'page_actions_post_reactions_haha_total', name: "Me divierte", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/medivierte.png"), inter: true},
        {id: 'page_actions_post_reactions_sorry_total', name: "Me entristece", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/meentristece.png"), inter: true},
        {id: 'page_actions_post_reactions_anger_total', name: "Me enoja", number: "-", description: "", ico: require("@/assets/img/local/metrics/facebook/meenoja.png"), inter: true},


        {id: 'page_views_total', name: "Interacciones", number: "-", description: "Suma de reacciones, comentarios, compartidos y clics.", ico: require("@/assets/img/local/metrics/facebook/interacciones.svg")},
        {id: 'page_impressions', name: "Impresiones", number: "-", description: "total de veces que se han visto las publicaciones", ico: require("@/assets/img/local/metrics/facebook/impresiones.svg")},
        {id: 'page_post_engagements', name: "Alcance", number: "-", description: "usuarios únicos que han llegado a las publicaciones", ico: require("@/assets/img/local/metrics/facebook/alcance.svg")},
        {id: 'page_views_total', name: "Visitas al perfil", number: "-", description: "Nº de visitas del perfil de la página", ico: require("@/assets/img/local/metrics/facebook/visitas.svg")},
        {id: 'page_consumptions', name: "Contenido", number: "-", description: "Nº de veces que las personas hicieron clic en algunos de tus contenidos", ico: require("@/assets/img/local/metrics/facebook/comentarios.svg")},
      ],
      dataMockedInstagram: [
        {id: '', name: "Nº de publicaciones", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/npublicaciones.svg")},
        {id: '', name: "Nº de seguidores", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/nseguidores.svg")},
        {id: '', name: "Nº de Likes", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/likes.svg")},

        {id: 'impressions', name: "Nº Impresiones", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true},
        {id: 'email_contacts', name: "Nº de clicks sobre email", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true},
        {id: 'phone_call_clicks', name: "Nº de clicks sobre teléfono", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true},
        {id: 'text_message_clicks', name: "Nº de clicks sobre mensaje", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true},
        {id: 'get_directions_clicks', name: "Nº  de clicks sobre dirección", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true},
        {id: 'website_clicks', name: "Nº clicks sobre website", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true},
        {id: 'profile_views', name: "Nº visitas al perfil", number: "-", description: "", ico: require("@/assets/img/local/metrics/instagram/visitasperfil.svg"), inter: true},

        {id: 'total_interactions', name: "Interacciones", number: "-", description: "total de veces que se han visto las publicaciones", ico: require("@/assets/img/local/metrics/instagram/impresiones.svg")},
        {id: 'reach', name: "Alcance", number: "-", description: "usuarios únicos que han llegado a las publicaciones", ico: require("@/assets/img/local/metrics/instagram/alcance.svg")},
        {id: 'comments', name: "Comentarios", number: "-", description: "Nº de Comentarios", ico: require("@/assets/img/local/metrics/instagram/comentarios.svg")},
        {id: 'replies', name: "Respuestas", number: "-", description: "Nº de Respuestas", ico: require("@/assets/img/local/metrics/instagram/comentarios.svg")},
        {id: 'saves', name: "Guardados", number: "-", description: "Nº de guardados", ico: require("@/assets/img/local/metrics/instagram/guardados.svg")},
        {id: 'shares', name: "Compartidos", number: "-", description: "Nº de compartidos", ico: require("@/assets/img/local/metrics/instagram/compartidos.svg")},
      ],
      dataActionsGoogle: [
        {name: "Menu", metric: "BUSINESS_FOOD_MENU_CLICKS", number: null, description: "Clics para ver o interactuar con el contenido del menú.", ico: require("@/assets/img/local/metrics/google/menu.svg")},
        {name: "Llamadas", metric: "CALL_CLICKS", number: null, description: "clics en el botón de llamada del perfil del negocio.", ico: require("@/assets/img/local/metrics/google/llamadas.svg")},
        {name: "Mensajes", metric: "BUSINESS_CONVERSATIONS", number: null, description: "El número de conversaciones de mensajes recibidos.", ico: require("@/assets/img/local/metrics/google/mensajes.svg")},
        {name: "Reservas", metric: "BUSINESS_BOOKINGS", number: null, description: "El número de reservas recibidas. ", ico: require("@/assets/img/local/metrics/google/reservas.svg")},
        {name: "Cómo llegar", metric: "BUSINESS_DIRECTION_REQUESTS", number: null, description: "veces que se solicitó dirección a la ubicación.", ico: require("@/assets/img/local/metrics/google/comollegar.svg")},
        {name: "Clics al sitio web", metric: "WEBSITE_CLICKS", number: null, description: "Veces que se hizo clic en el sitio web.", ico: require("@/assets/img/local/metrics/google/clics.svg")},
      ],
      dataSearchDesktop: null,
      dataMetrics: null,
      dataViewsProfile: null,
      dataChartViews: null,
      totalView: null,
      interactions_ranking: null,
      totalInteractions: null,
      notData: false,
      restaurantsMoreSearch: null,
      imagesKey: {
        BUSINESS_IMPRESSIONS_DESKTOP_MAPS: require("@/assets/img/local/metrics/google/search-maps-desktop.svg"),
        BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: require("@/assets/img/local/metrics/google/search-desktop.svg"),
        BUSINESS_IMPRESSIONS_MOBILE_MAPS: require("@/assets/img/local/metrics/google/search-maps.svg"),
        BUSINESS_IMPRESSIONS_MOBILE_SEARCH: require("@/assets/img/local/metrics/google/search-movil.svg"),
      },
      namesKey: {
        BUSINESS_IMPRESSIONS_DESKTOP_MAPS: "Google Maps (ordenador)",
        BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: "Búsqueda de Google  (ordenador)",
        BUSINESS_IMPRESSIONS_MOBILE_MAPS: "Google Maps  (móvil)",
        BUSINESS_IMPRESSIONS_MOBILE_SEARCH: "Búsqueda de Google  (móvil)",
      },
      colorKey: {
        BUSINESS_IMPRESSIONS_DESKTOP_MAPS: "#DC2B1F",
        BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: "#1970E8",
        BUSINESS_IMPRESSIONS_MOBILE_MAPS: "#F19700",
        BUSINESS_IMPRESSIONS_MOBILE_SEARCH: "#188A42",
      },
    };
  },
  methods: {
    downloadXlsFile() {
      this.$store.commit(types.DOWNLOADING_EXCEL, true);
      const _startDate = this.startDate.replaceAll("/", "-")
      const _endDate = this.endDate.replaceAll("/", "-")

      this.downloadExcel({startDate: _startDate, endDate: _endDate})
    },
    processData(data) {
      this.interactions_ranking = data?.interactions_ranking;
      this.totalView = data.main_metrics_data[data.main_metrics_data.length - 1].total_impressions;
      this.totalInteractions = data?.submetrics?.total;
      this.dataViewsProfile = data.main_metrics_data.filter((e) => Object.keys(this.namesKey).includes(e.metric));
      this.createDataChartViews(this.dataViewsProfile);
      this.dataMetrics = {timeSeries: data.submetrics.uniqueTimeSeries};
      this.dataActionsGoogleFilteredByBrand.forEach((e) => {
        if (data.submetrics.metrics.filter((x) => e.metric === x.metric_name).length > 0) {
          e.number = data.submetrics.metrics.filter((x) => e.metric === x.metric_name)[0].count;
        } else {
          e.hide = true;
        }
      });
    },
    getNumberByMetricByType(key, center) {
      let metric = this.dataViewsProfile.filter((e) => e.metric === key);
      let num = 0;
      if (metric.length > 0) {
        try {
          num = metric[0].summary.filter(el => el.Business === center)?.[0].total;
        } catch (error) {
          //error bloque
        }
      }
      return num;
    },
    formatCustom(val) {
      this.$formatNumber(val, 2, 2);
    },
    createDataChartViews(dataViews) {
      let data = {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            backgroundColor: [],
            hoverOffset: 4,
          },
        ],
      };
      Object.keys(dataViews).forEach((e) => {
        if (!["total", "uniqueTimeSeries"].includes(e)) {
          data.labels.push(dataViews[e].metric_es);
          data.datasets[0].data.push(dataViews[e].total_impressions);
          data.datasets[0].backgroundColor.push(this.colorKey[e] || this.colorKey[dataViews[e]?.metric]);
        }
      });
      this.dataChartViews = data;
    },
    async getData() {
      this.$store.commit("LOADING", true);
      if (this.hasPremium) {
        try {
          if (this.typeNode === "center") {
            const res = await SrvLocalMetrics.getLocalMetricsKeywordsSearch(this.$moment(this.$store.getters.getRangeDatesMetrics[0]).format("DD/MM/YYYY"), this.$moment(this.$store.getters.getRangeDatesMetrics[1]).format("DD/MM/YYYY"), this.$store.getters.getNode);
            if (res.status === 200) {
              this.dataSearchDesktop = res.data;
            }
          }
          const resViews = await SrvLocalMetrics.getLocalMetricsGoogle(this.$moment(this.$store.getters.getRangeDatesMetrics[0]).format("YYYY-MM-DD"), this.$moment(this.$store.getters.getRangeDatesMetrics[1]).format("YYYY-MM-DD"));
          if (resViews.status === 200) {
            this.processData(resViews.data);
          }


        } catch (e) {
          this.notData = true
          this.$store.commit("LOADING", false);
        }

      } else {
        if (this.typeNode === "center") {
          this.dataSearchDesktop = DataKeywords;
        }
        this.processData(DataMetricsMock);
      }
      this.$store.commit("LOADING", false);
    },
    setRange(val) {
      this.$store.commit("LOCAL_RANGE_DATES", val);
      this.getData();
    },
  },
  computed: {
    startDate() {
      return this.$moment(this.$store.getters.getRangeDatesMetrics[0]).format("YYYY/MM/DD")
    },
    endDate() {
      return this.$moment(this.$store.getters.getRangeDatesMetrics[1]).format("YYYY/MM/DD")
    },
    dataActionsGoogleFilteredByBrand() {
      if (this.isAdverbia) {
        return this.dataActionsGoogle.filter(e => e.name !== 'Menu')
      }
      return this.dataActionsGoogle
    },
    viewsProfile() {
      let data = [];
      Object.keys(this.dataViewsProfile).forEach((e) => {
        if (!["total", "uniqueTimeSeries", "submetrics"].includes(e)) {
          data.push({porcentaje: this.dataViewsProfile[e].porcentaje.substring(0, this.dataViewsProfile[e].porcentaje.length - 1), count: this.dataViewsProfile[e].total_impressions, name: this.namesKey[e] || this.namesKey[this.dataViewsProfile[e].metric], ico: this.imagesKey[e] || this.imagesKey[this.dataViewsProfile[e].metric], color: this.colorKey[e] || this.colorKey[this.dataViewsProfile[e].metric]});
        }
      });
      return data.sort((a, b) => b.count - a.count);
    },
  },
  created() {
    this.getData();
  },
  watch: {
    filterApply: function () {
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.boxMetricsBySite {
  border: 1px solid #cecece;
  background-color: #fff;
  border-radius: 4px;
  margin: auto 10px;
  padding: 25px;

  .box-action-resize {
    transition: all 0.25s ease;
  }

  .box-action-resize:hover {
    transform: scale(1.1);
  }

  .facebookInteractions,
  .instagramInteractions {
    gap: 6%;
    background-color: #F4F6FC;
    text-align: center;
    padding-top: 28px;
    padding-bottom: 16px;

    img {
      width: 30px;
    }

    .name {
      font-size: 14px;
      color: #3C5A99;
      font-weight: 600;
      margin-top: 13px;
      margin-bottom: 17px;
    }

    .number {
      font-weight: bold;
      font-size: 18px;
    }
  }

  .instagramInteractions {
    justify-content: center;
    gap: 1rem;

    .name {
      color: #b51c32;
      font-weight: 400;
    }
  }

  .borderLogo {
    border: 1px solid #cecece;
    margin-top: 10px;
  }

  .boxCharts {
    gap: 4%;

    .boxPersonas {
      width: 54%;
    }

    .boxInteracciones {
      width: 42%;
    }
  }

  .boxGrupoAcciones {
    gap: 20px;

    &.interactions {
      gap: 0;
    }

    .boxAccion {
      flex: 1 1 0px;
    }
  }

  .boxSearchs {
    gap: 20px;

    .search {
      flex-direction: column;
      width: 40%;

      .numberInResult {
        background-color: #f4f6fc;
        padding: 15px;
        flex: 1 1 0%;
      }
    }

    .searchRestaurant {
      width: 60%;
    }

    .search,
    .searchRestaurant {
      .infoForRestaurant {
        background-color: #f4f6fc;
        padding: 15px;

        .rowCenter {
          margin: 0;
          padding: 5px;
          border-bottom: 2px solid #ffffff;
        }
      }
    }
  }
}
</style>
