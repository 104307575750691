<template lang="pug">
  div.pt-5
    breadcrumbs(:borderBottom="false")

    div(class="margin-bottom-0 gridFull pa-0" :class="{ premiumDisabled: !this.hasPremium }")
      skeleton-card(v-if="loading")

      div(class="tableTasks")
        div(class="head")
          div(class="rowtask d-flex")
            div(class="coltask colId") #[translate ID Tarea]
            div(class="coltask colDate")
              span.d-flex.text-truncate #[translate Fecha]
              .boxOrder.pl-2
                sort(:orderBy="'-date'" :orderByActual="ordering" @sort="setOrder($event)")
            div(class="coltask colUser")
              span.d-flex.text-truncate #[translate Usuario]
                .boxOrder.pl-2
                  sort(:orderBy="'-user'" :orderByActual="ordering" @sort="setOrder($event)")
            div(class="coltask colType")
              span.d-flex.text-truncate #[translate Tipo]
                .boxOrder.pl-2
                  sort(:orderBy="'-update_type'" :orderByActual="ordering" @sort="setOrder($event)")
            div(class="coltask colStatus")
              span.d-flex.text-truncate #[translate Estado]
                .boxOrder.pl-2
                  sort(:orderBy="'-status'" :orderByActual="ordering" @sort="setOrder($event)")
            div(class="coltask colFechaProgramada")
              span.d-flex.text-truncate #[translate Fecha programada]
                .boxOrder.pl-2
                  sort(:orderBy="'-schedule_date'" :orderByActual="ordering" @sort="setOrder($event)")
            div(class="coltask colScheduled")
              span.d-flex.text-truncate #[translate Programada]
                .boxOrder.pl-2
                  sort(:orderBy="'-is_scheduled'" :orderByActual="ordering" @sort="setOrder($event)")
            div(class="coltask colActions")
              span.d-flex.text-truncate #[translate Acciones]


        div(class="body")
          div.hover-resize(class="rowtask" v-for="(task, index) in tasks" :key="index")
            div(class="data d-flex")
              div.px-6(class="coltask colId") {{ task.id }}
              div(class="coltask colDate") {{ $moment(task.date).format("DD/MM/YYYY") }}
              div(class="coltask colUser") {{ task.user }}
              div(class="coltask colType") {{ task.update_type }}
              div(class="coltask colStatus" :class="{ pending: task.status === 'P', publicated: task.status === 'C', failed: task.status === 'F', deleted: task.status === 'D' }") {{getStatusPretty(task.status) }}
              div(class="coltask colFechaProgramada") {{ task.schedule_date ? $moment(task.schedule_date).format("DD/MM/YYYY HH:mm") : '-' }}
              div(class="coltask colScheduled")
                span(class="boxScheduled" :class="{ 'on': task.is_scheduled }")

              div(class="coltask colActions d-flex justify-center")
                button.pr-4(v-if="task.task_details.post_details!==undefined && task.is_scheduled && !task.executed && enabledEditActions.includes(task.task_details.post_details.topicType)" @click="handleEditionClick(task)")
                  v-tooltip(top color="#222226" max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                    template(v-slot:activator="{ on, attrs }")
                      img(:src="images.edit" v-on="on" v-bind="attrs" width="20")
                    span #[translate Editar]
                button(@click="task.opened = !task.opened")
                  v-tooltip(top color="#222226" max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                    template(v-slot:activator="{ on, attrs }")
                      img(:src="images.details" v-on="on" v-bind="attrs" width="20")
                    span #[translate Ver detalle]

            div(class="subdata" v-if="task.opened")
              div(class="d-flex" v-for="(singleTask, index) in task.post_tasks" :key="index")
                div(class="coltask colId")
                  Nombre Local:
                    strong {{ singleTask.business }}

                div(class="coltask colStatus" :class="{ pending: singleTask.remote_status === 'P', publicated: singleTask.remote_status === 'C', failed: singleTask.remote_status === 'F', deleted: singleTask.remote_status === 'D' }") {{ getStatusPretty(singleTask.remote_status) }}
                div(class="coltask colDetailInGoogle")
                  a(v-if="singleTask.remote_url" :href="singleTask.remote_url" target="_blank") Ver post en google

              div(class="d-flex" v-for="(singleTask, index) in task.info_tasks" :key="index")
                div(class="coltask colId") Nombre Local:
                  strong {{ singleTask.business }}

                div(class="coltask colStatus"
                  :class="{ pending: singleTask.remote_status === 'P', publicated: singleTask.remote_status === 'C', failed: singleTask.remote_status === 'F', deleted: singleTask.remote_status === 'D' }") {{ getStatusPretty(singleTask.remote_status) }}

              div(class="d-flex" v-for="(singleTask, index) in task.imgs_tasks" :key="index")
                div(class="coltask colId")
                  Nombre Local:
                    strong {{ singleTask.business }}

                div(class="coltask colStatus"
                  :class="{ pending: singleTask.remote_status === 'P', publicated: singleTask.remote_status === 'C', failed: singleTask.remote_status === 'F', deleted: singleTask.remote_status === 'D' }") {{ getStatusPretty(singleTask.remote_status) }}

              div(class="d-flex" v-for="(singleTask, index) in task.blocks_tasks" :key="index")
                div(class="coltask colId") Nombre Local:
                  strong {{ singleTask.business_name }}

                div(class="coltask colStatus"
                  :class="{ pending: singleTask.remote_status === 'P', publicated: singleTask.remote_status === 'C', failed: singleTask.remote_status === 'F', deleted: singleTask.remote_status === 'D' }") {{ getStatusPretty(singleTask.remote_status) }}

              div(class="d-flex" v-for="(singleTask, index) in task.service_tasks" :key="index")
                div(class="coltask colId") Nombre Local:
                  strong {{ singleTask.business_name }}

                div(class="coltask colStatus"
                  :class="{ pending: singleTask.remote_status === 'P', publicated: singleTask.remote_status === 'C', failed: singleTask.remote_status === 'F', deleted: singleTask.remote_status === 'D' }") {{ getStatusPretty(singleTask.remote_status) }}



          div(class="rowtask" v-if="!tasks || tasks.length === 0")
            div(class="data d-flex")
              p(class="text-center pa-5") No existen tareas

      add-event(:visible="actionOpenModal === 'EVENT'"  @close="actionOpenModal=''; actionOpenModal='default'" :edition-data="editionData" @handleSuccess="dataUpdate")
      add-quotation(:visible="actionOpenModal === 'OFFER'"  @close="actionOpenModal=''; actionOpenModal='default'" :edition-data="editionData" @handleSuccess="dataUpdate" )
      add-new(:visible="actionOpenModal === 'STANDARD'"  @close="actionOpenModal=''; actionOpenModal='default'" :edition-data="editionData" @handleSuccess="dataUpdate")
</template>
<script>
import {mapGetters} from "vuex";
import SkeletonCard from "@/components/common/skeletonCard.vue";
import SrvLocal from "@/services/local.js";
import SrvCommon from "@/services/common.js";
import commonMixins from "@/mixins/common.js";
import localMixins from "@/mixins/local.js";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import addEvent from "@/components/local/posts/addEvent.vue";
import addQuotation from "@/components/local/posts/addQuotation.vue";
import addNew from "@/components/local/posts/addNew.vue";
import GlobalFilter from "@/components/common/GlobalFilter.vue";
import Sort from "@/components/common/Sort.vue";

export default {
  mixins: [commonMixins, localMixins],
  components: {SkeletonCard, Breadcrumbs, addEvent, addQuotation, addNew, GlobalFilter, Sort},
  data() {
    return {
      loading: false,
      tasks: null,
      nextScrollUrl: null,
      nextCall: false,
      actionOpenModal: null,
      enabledEditActions: ["STANDARD", "EVENT", "OFFER"],
      editionData: {},
      ordering: "-date",
      statusOptions: [{value: 'Completada', key: "C"}, {value: 'Pendiente', key: "P"}, {value: 'Fallido', key: "F"}, {value: 'Eliminado', key: "D"}],
      updateType: [{value: 'POST', key: "POST"}, {value: 'INFO', key: "INFO"}, {value: 'IMG', key: "IMG"}, {value: 'BLOCK', key: "BLOCK"}, {value: 'VIDEO', key: "VIDEO"}],
      images: {
        edit: require("@/assets/img/local/edit.svg"),
        details: require("@/assets/img/local/view.svg")
      }
    };
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  created() {
    this.getData();
    this.scroll();
  },
  methods: {
    setOrder(val) {
      this.ordering = val;
      this.nextScrollUrl = null
      this.getData()
    }
    ,
    dataUpdate() {
      this.tasks = []
      this.nextScrollUrl = null
      this.getData()
    }
    ,
    getFormatedData(task) {
      const post_details = task.task_details.post_details

      const date_hour = this.$moment(task.schedule_date).format("HH:mm:ss");
      return {
        eventName: post_details.eventName ?? '',
        id: task.id,
        startDate: post_details.startMonth && post_details.startDay && post_details.startYear ? new Date(`${post_details.startMonth}-${post_details.startDay}-${post_details.startYear}`) : null,
        endDate: post_details.endMonth && post_details.endDay && post_details.endYear ? new Date(`${post_details.endMonth}-${post_details.endDay}-${post_details.endYear}`) : null,
        dateProgramation: new Date(task.schedule_date),
        timeProgramation: date_hour,
        summary: post_details.summary ?? '',
        actionTypeUrl: post_details.actionTypeUrl ?? '',
        local_image: task.images.length ? task.images?.[0].image : null,
        couponCode: post_details.couponCode ?? null,
        redeemOnlineUrl: post_details.redeemOnlineUrl ?? null,
        termsConditions: post_details.termsConditions ?? null,
        startTime: post_details.startMinute && post_details.startHour ? `${post_details.startHour}:${post_details.startMinute}` : null,
        endTime: post_details.endMinute && post_details.endHour ? `${post_details.endHour}:${post_details.endMinute}` : null,
        actionType: post_details.actionType ?? null
      }
    }
    ,
    handleEditionClick(task) {
      this.editionData = this.getFormatedData(task)

      this.actionOpenModal = task.task_details.post_details.topicType
    }
    ,
    getStatusPretty(key) {
      if (key == "F") {
        return "Fallida";
      }
      if (key == "C") {
        return "Completada";
      }
      if (key == "P") {
        return "Pendiente";
      }
      if (key == "D") {
        return "Eliminada";
      }
    }
    ,
    async getData() {
      if (this.hasPremium) {
        if (!this.nextScrollUrl) {
          const res = await SrvLocal.getTaskStatus(this.ordering);
          if (res.status === 200) {
            res.data.results.map((e) => {
              e.opened = false;
            });
            this.tasks = res.data.results;
            this.reportCounts = res.data.count;
            this.nextScrollUrl = res.data.next;
            this.loading = false;
          }
        } else {
          const res = await SrvCommon.getNext(this.nextScrollUrl);
          if (res.status === 200) {
            this.nextScrollUrl = res.data.next;
            this.reportCounts = res.data.count;
            res.data.results.forEach((element) => {
              this.tasks.push(element);
            });
            this.nextCall = false;
          }
        }
      }
    }
    ,
    scroll() {
      window.onscroll = () => {
        if (this.$route.name === "LocalStatusTasks") {
          var body = document.body,
            html = document.documentElement;
          var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
          height = parseInt(height, 10) - 1;
          let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height || parseInt((document.documentElement.scrollTop + window.innerHeight).toFixed(0), 10) + 1 == height || (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height + 1;
          if (this.nextScrollUrl && bottomOfWindow && !this.nextCall) {
            this.nextCall = true;
            this.getData();
          }
        }
      };
    }
    ,
  },
  watch: {
    filterApply: function () {
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.boxOrder {
  float: right;
  display: flex;
  align-items: center;
}

.tableTasks {
  .rowtask {
    border-bottom: 1px solid #e7e7e7;
    align-items: center;

    .boxOrder {
      float: right;
    }

    .coltask {
      overflow-wrap: anywhere;
      align-self: stretch;
      align-items: center;
      display: flex;
    }

    .coltask:not(:last-child) {
      border-right: 1px solid #e7e7e7;
    }

    .colId {
      width: 8%;
    }

    .colDate {
      width: 10%;
    }

    .colUser {
      width: 26%;
    }

    .colType {
      width: 8%;
    }

    .colStatus {
      width: 13%;
    }

    .colFechaProgramada {
      width: 14%;
    }

    .colScheduled {
      width: 11%;
    }

    .colActions {
      width: 12%;
    }
  }

  .head {
    font-size: 12px;

    .coltask {
      padding: 15px 10px;
    }
  }

  .body {
    font-size: 14px;

    .rowtask:nth-child(even) {
      background-color: #f7f8f9;
    }

    .rowtask {
      .coltask {
        padding: 10px;

        &.colActions {
          color: #608df2;

          button {
            display: flex;
            align-items: center;

            svg {
              margin-left: 10px;

              &.open {
                rotate: 90deg;
              }
            }
          }
        }

        &.colStatus {
          &.publicated {
            color: #7ed321;
          }

          &.failed,
          &.deleted {
            color: #f93549;
          }

          &.pending {
            color: #f7a669;
          }
        }

        &.colScheduled {
          .boxScheduled {
            width: 16px;
            height: 16px;
            margin: 0 auto;
            display: block;
            border: 1px solid #707070;
            border-radius: 16px;

            &.on {
              background-color: #6DDAFC;
            }
          }
        }
      }

      .data {
        align-items: center;
      }

      .subdata {
        border-top: 1px solid #e7e7e7;

        .coltask {
          border-right: 0;

          &.colId {
            margin-left: 23%;
            flex-basis: 30%;
            border-left: 1px solid #e7e7e7;
          }

          &.colStatus {
            flex-basis: 18%;
          }

          &.colDetailInGoogle {
            a {
              text-decoration: none;
              color: #608df2;
            }
          }
        }
      }
    }
  }
}
</style>
