<template lang="pug">

  v-row.px-2.ma-0
    //GRID NÚMERO DE OPINIONES
    div.margin-bottom-0.col-12
      skeleton-card(v-if="isLoading && !dataSummaryDelivery.length")
      v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading && dataSummaryDelivery.length")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card Opiniones y Valoración media
            span.ml-1.tDelivery Delivery
          .block-help.ml-auto
            v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="40" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              span {{helpNOp}}
            v-icon.mb-1(v-if="typeNode!=='group'" @click="goFeedback('Delivery')") mdi-chevron-right
        .card-content
          #tableOpionnesYValoracionMedia
            .head
              .row(:style="gridOpinions")
                .col.colName &nbsp;
                .col.colPeriodo
                .col.colValoracionMedia
                  span Valoración media
                .col.text-center
                  span Total Opiniones
                .col.text-center.colTotalOpiniones
                  span Media Opiniones
                .col.colJustEat
                  img(:src="img.imgJustEat")
                .col
                  span nº opiniones
                .col.colJustEatOpiniones.text-center
                  span Media Opiniones
                .col.colGlovo
                  img(:src="img.imgGlovo")

                .col
                  span nº opiniones
                .col.colGlovoOpiniones.text-center
                  span Media Opiniones

                .col.colUberEats
                  img(:src="img.imgUberEats")
                .col.colUberEatsOpiniones
                  span nº opiniones
                .col.text-center.px-2
                  span Media Opiniones

            .body
              .row.hover-resize(v-for="(item, index) in dataSummaryDelivery" :key="index" :style="gridOpinions")
                .col.colName
                  span(v-if="index === 0") {{item.name}}
                  span.link-text(v-else @click="goItemNextLevel(item)") {{ item.name }}
                .col.colPeriodo
                  .boxPeriodo Período
                  .boxHistorico Histórico
                .col.colValoracionMedia.font-weight-medium
                  .boxPeriodo(v-if="item.review_rating_global" :class="getColorNote(8)") {{ item.review_rating_global}}
                  .boxPeriodo(v-else) -
                  .boxHistorico(v-if="item.historical_review_rating_global" :class="getColorNote(6)") {{ item.historical_review_rating_global }}
                  .boxHistorico(v-else) -
                .col.font-weight-medium
                  .boxPeriodo {{ item.review_count }}
                  .boxHistorico {{ item.historical_review_count }}
                .col.colTotalOpiniones
                  .boxPeriodo
                    span.pr-1 {{ item.avg_reviews_per_center  | formatNumber }}
                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                      template(v-slot:activator="{ on, attrs }")
                        span.mb-0.arrow(v-bind="attrs" v-on="on" v-if="item.tend_avg_reviews_per_center" :class="getArrow(item.avg_reviews_per_center, item.tend_avg_reviews_per_center)")
                      span {{ item.tend_avg_reviews_per_center | formatNumber }}
                  .boxHistorico -
                .col.colJustEat
                  .boxPeriodo
                    just-eat-rating(:ranking="item['delivery_rating']['GRB']['site_rating'] || item['delivery_rating']['JUS']['site_rating'] || '-'")
                  .boxHistorico
                    just-eat-rating(v-if="item['delivery_rating']['GRB']['previous_period_site_rating'] || item['delivery_rating']['JUS']['previous_period_site_rating']" :ranking="item['delivery_rating']['UBE']['previous_period_site_rating'] || item['delivery_rating']['JUS']['previous_period_site_rating'] || '-'" :smaller="true")
                    span(v-else) -
                .col
                  .boxPeriodo {{ item['delivery_rating']['GRB']['review_count'] || item['delivery_rating']['JUS']['review_count'] || '-' }}
                  .boxHistorico {{ item['delivery_rating']['GRB']['historical_review_count'] || item['delivery_rating']['JUS']['historical_review_count'] || '-' }}
                .col.colJustEatOpiniones
                  .boxPeriodo
                    span(v-if="item['delivery_rating']['GRB'].avg_review_per_center || item['delivery_rating']['JUS'].avg_review_per_center")  {{ (item['delivery_rating']['GRB'].avg_review_per_center || item['delivery_rating']['JUS'].avg_review_per_center)  | formatNumber }}
                    span(v-else) -
                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                      template(v-slot:activator="{ on, attrs }")
                        span.mb-0.arrow(v-bind="attrs" v-on="on" v-if="item['delivery_rating']['GRB'].tend_avg_review_per_center || item['delivery_rating']['JUS'].tend_avg_review_per_center" :class="getArrow(item['delivery_rating']['GRB'].avg_review_per_center || item['delivery_rating']['JUS'].avg_review_per_center, item['delivery_rating']['GRB'].tend_avg_review_per_center || item['delivery_rating']['JUS'].tend_avg_review_per_center)")
                      span {{(item['delivery_rating']['GRB'].tend_avg_review_per_center || item['delivery_rating']['JUS'].tend_avg_review_per_center) | formatNumber }}
                  .boxHistorico -
                .col.colGlovo
                  .boxPeriodo
                    div.d-flex.justify-center.items-center(v-if="!item['delivery_rating']['GLV']['site_rating'] || formatNumber(item['delivery_rating']['GLV']['site_rating']) === '-'")
                      span -
                    v-row.justify-center.items-center.no-bottom(v-else)
                      v-col.col-6.d-flex.justify-center.items-center
                        img.mr-0(:src="img.imgRatingGlovoRegular" style="width:20px;height:20px")
                        span.text-12 {{item['delivery_rating']['GLV']['review_count_positive']}}
                      v-col.col-6.d-flex.justify-center.items-center
                        img.mr-0(:src="img.imgRatingBad" style="width:20px;height:20px")
                        span.text-12 {{item['delivery_rating']['GLV']['review_count_negative']}}
                  .boxHistorico
                    div.d-flex.justify-center.items-center(v-if="!item['delivery_rating']['GLV']['historical_site_rating'] || formatNumber(item['delivery_rating']['GLV']['historical_site_rating']) === '-'")
                      span -
                    div.d-flex.justify-center.items-center(v-else)
                      GlovoRating(:size="15" :percent="item['delivery_rating']['GLV']['historical_site_rating'] || 0 ")
                      span {{ item['delivery_rating']['GLV']['historical_site_rating'] | formatNumber }}%
                .col
                  .boxPeriodo {{ item['delivery_rating']['GLV']['review_count'] || '-' }}
                  .boxHistorico {{ item['delivery_rating']['GLV']['historical_review_count'] || '-' }}
                .col.colGlovoOpiniones
                  .boxPeriodo
                    span(v-if="item['delivery_rating']['GLV'].avg_review_per_center")  {{ item['delivery_rating']['GLV'].avg_review_per_center  | formatNumber }}
                    span(v-else) -
                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                      template(v-slot:activator="{ on, attrs }")
                        span.mb-0.arrow(v-bind="attrs" v-on="on" v-if="item['delivery_rating']['GLV'].tend_avg_review_per_center" :class="getArrow(item['delivery_rating']['GLV'].avg_review_per_center, item['delivery_rating']['GLV'].tend_avg_review_per_center)")
                      span {{ item['delivery_rating']['GLV'].tend_avg_review_per_center | formatNumber }}
                  .boxHistorico -
                .col.colUberEats
                  .boxPeriodo
                    div.d-flex.justify-center.items-center(v-if="!item['delivery_rating']['UBE']['site_rating'] || formatNumber(item['delivery_rating']['UBE']['site_rating']) === '-'")
                      span -
                    div.d-flex.justify-center.items-center(v-else)
                      img(:src="img.imgStar")
                      span {{ item['delivery_rating']['UBE']['site_rating'] | formatNumber }}

                  .boxHistorico
                    div.d-flex.justify-center.items-center(v-if="!item['delivery_rating']['UBE']['historical_site_rating'] || formatNumber(item['delivery_rating']['UBE']['historical_site_rating']) === '-'")
                      span -
                    div.d-flex.justify-center.items-center(v-else)
                      img(:src="img.imgStar")
                      span {{ item['delivery_rating']['UBE']['historical_site_rating'] | formatNumber }}
                .col.colUberEatsOpiniones
                  .boxPeriodo {{ item['delivery_rating']['UBE']['review_count'] || '-' }}
                  .boxHistorico {{ item['delivery_rating']['UBE']['historical_review_count'] || '-' }}
                .col
                  .boxPeriodo
                    span(v-if="item['delivery_rating']['UBE'].avg_review_per_center") {{ item['delivery_rating']['UBE'].avg_review_per_center  | formatNumber }}
                    span(v-else) -
                    v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                      template(v-slot:activator="{ on, attrs }")
                        span.mb-0.arrow(v-bind="attrs" v-on="on" v-if="item['delivery_rating']['UBE'].tend_avg_review_per_center" :class="getArrow(item['delivery_rating']['UBE'].avg_review_per_center, item['delivery_rating']['UBE'].tend_avg_review_per_center)")
                      span {{ item['delivery_rating']['UBE'].tend_avg_review_per_center | formatNumber }}
                  .boxHistorico -





    //GRID NÚMERO DE OPINIONES
    div.margin-bottom-0.col-12
      skeleton-card(v-if="isLoading && !dataSummaryDelivery.length")
      v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading && dataSummaryDelivery.length")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card Datos Webs
            span.ml-1.tDelivery Delivery
          .block-help.ml-auto
            v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="40" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              span {{helpNOp}}
            v-icon.mb-1(v-if="typeNode!=='group'" @click="goFeedback('Delivery')") mdi-chevron-right
        .card-content
          #tableWebsDelivery
            .head
              v-row.no-gutters.items-center.py-1
                div.offset-2
                v-col.px-1
                  span {{ isAdverbia ? 'Nº de Opiniones' : 'Nº de Pedidos' }}
                v-col.px-1
                  span Nº de comentarios
                v-col.px-1
                  span Pedidos Valoración Positiva
                v-col.px-1
                  span Pedidos Valoración Negativa
                v-col.px-1
                  span % pedidos Erróneos
                v-col.px-1
                  span % pedidos Cancelados
                v-col.px-1
                  span Cancelación local
                v-col.px-1
                  span Cancelación web
                v-col.px-1
                  span Tiempo de Espera (m.)
                v-col.px-1
                  span Conversion
                v-col.px-1
                  span % new users
                v-col.px-1.col-2
                  span Puntuación / Satisfacción

            .body
              v-row.divider.no-gutters(v-for="(item, index) in dataSummaryDelivery[0]['delivery_rating']" :key="index" :class="{ hide: !deliverySitesStatus[item.code] }")
                v-col.d-flex.flex-column.col-2
                  v-row.d-flex.justify-center.align-center.no-gutters
                    img(:src="item.logo" style="width:30px; height:30px")
                    span.pl-2.font-weight-medium {{ item.name }}
                  //TODO: add missing translations
                  v-row.justify-center.align-center.no-gutters
                    v-tooltip(right color="#222226")
                      template(v-slot:activator="{ on, attrs }")
                        span.lastPeriod(v-bind="attrs" v-on="on") Periodo Anterior
                      span.tooltip-help {{ dateRanges.previous_period.after }} - {{ dateRanges.previous_period.before }}

                v-col.d-flex.flex-column.align-center
                  v-row.no-gutters.d-flex.align-center.justify-center
                    v-col.col-6
                      span(v-if="item.n_orders") {{ item.n_orders || 0 }}
                      span(v-else) -
                    v-col.col-6
                      v-tooltip(v-if="item.tend_n_orders" right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                        template(v-slot:activator="{ on, attrs }")
                          span.ml-1.mb-0.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.n_orders, item.tend_n_orders)")
                        span {{ item.tend_n_orders | formatNumber }}
                  v-row.no-gutters.d-flex.align-center.justify-center
                    v-col.col-6
                      span(v-if="item.previous_period_n_orders" class="lastPeriod") {{ item.previous_period_n_orders || 0 }}
                      span(v-else) -
                    v-col.col-6
                      v-tooltip(v-if="item.previous_period_tend_n_orders" right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                        template(v-slot:activator="{ on, attrs }")
                          span.ml-1.mb-0.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.previous_period_n_orders, item.previous_period_tend_n_orders)")
                        span {{ item.previous_period_tend_n_orders | formatNumber }}

                v-col.d-flex.flex-column.align-center
                  v-row.no-gutters.d-flex.align-center.justify-center
                    v-col.col-6
                      span {{ item.text_count || 0 }}
                    v-col.col-6
                      v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                        template(v-slot:activator="{ on, attrs }")
                          span.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.text_count, item.tend_text_count)")
                        span {{ item.tend_text_count | formatNumber }}
                  v-row.no-gutters.d-flex.align-center.justify-center
                    v-col.col-6
                      span.lastPeriod {{ item.previous_period_text_count || 0 }}
                    v-col.col-6.d-none
                      v-tooltip(v-if="item.previous_period_n_orders" right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                        template(v-slot:activator="{ on, attrs }")
                          span.ml-1.mb-0.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.previous_period_n_orders, item.tend_n_orders)")
                        span {{ item.tend_n_orders | formatNumber }}

                v-col.d-flex.flex-column.align-center
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span {{ item.n_positive_reviews || 0 }}
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span.lastPeriod {{ item.previous_period_n_positive_reviews || 0 }}

                v-col.d-flex.flex-column.align-center
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span {{ item.n_negative_reviews || 0 }}
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span.lastPeriod {{ item.previous_period_n_negative_reviews || 0 }}

                v-col.d-flex.flex-column.align-center
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.wrong_order_rate") {{ item.wrong_order_rate || 0 }}%
                    span(v-else) -
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.previous_period_wrong_order_rate" class="lastPeriod") {{ item.previous_period_wrong_order_rate || 0 }}%
                    span(v-else) -
                v-col.d-flex.flex-column.align-center
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.canceled_order_rate") {{ item.canceled_order_rate || 0 }}%
                    span(v-else) -
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.previous_period_canceled_order_rate" class="lastPeriod") {{ item.previous_period_canceled_order_rate || 0 }}%
                    span(v-else) -
                v-col.d-flex.flex-column.align-center
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.n_cancelled_by_local ") {{ item.n_cancelled_by_local  || 0 }}
                    span(v-else) -
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.previous_period_n_cancelled_by_local" class="lastPeriod") {{ item.previous_period_n_cancelled_by_local  || 0 }}
                    span(v-else) -
                v-col.d-flex.flex-column.align-center
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.n_cancelled_by_web") {{ item.n_cancelled_by_web || 0 }}
                    span(v-else) -
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.previous_period_n_cancelled_by_web" class="lastPeriod") {{ item.previous_period_n_cancelled_by_web || 0 }}
                    span(v-else) -
                v-col.d-flex.flex-column.align-center
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.wait_time") {{ item.wait_time || 0 }}
                    span(v-else) -
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.previous_period_wait_time" class="lastPeriod") {{ item.previous_period_wait_time || 0 }}
                    span(v-else) -
                v-col.d-flex.flex-column.align-center
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.conversion") {{ item.conversion || 0 }} %
                    span(v-else) -
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.previous_period_conversion" class="lastPeriod") {{ item.previous_period_conversion || 0 }} %
                    span(v-else) -
                v-col.d-flex.flex-column.align-center
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.new_users_rate") {{ item.new_users_rate || 0 }} %
                    span(v-else) -
                  v-row.no-gutters.d-flex.align-center.justify-center
                    span(v-if="item.previous_period_new_users_rate" class="lastPeriod") {{ item.previous_period_new_users_rate || 0 }} %
                    span(v-else) -
                v-col.d-flex.flex-column.align-center.col-2
                  v-row.no-gutters.d-flex.align-center.justify-space-between(style="width:100%")
                    div.d-flex.col-9.justify-center
                      just-eat-rating(v-if="item.code === 'JUS' && item.site_rating" :ranking="item.site_rating")
                      v-row.no-gutters.justify-center.items-center.no-bottom(v-else-if="item.code === 'GLV'")
                        div.d-flex.justify-center.items-center.mr-1
                          img.mr-0(:src="img.imgRatingGlovoRegular" style="width:20px;height:20px")
                          span.text-12 {{item.review_count_positive}}
                        div.d-flex.justify-center.items-center
                          img.mr-0(:src="img.imgRatingBad" style="width:20px;height:20px")
                          span.text-12 {{item.review_count_negative}}
                      img(v-else-if="item.code === 'UBE'" :src="img.imgStar")
                      span.ml-4(v-if="item.code === 'UBE'") {{ item.site_rating | formatNumber }}
                    div.d-flex.col-3.justify-start
                      v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                        template(v-slot:activator="{ on, attrs }")
                          span.ml-4.mb-0.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.site_rating, item.tend_review_site_rating)")
                        span(v-if="item.tend_review_site_rating") {{ item.tend_review_site_rating | formatNumber }}
                        span(v-else) -
                  v-row.no-gutters.d-flex.align-center.justify-space-between(style="width:100%")
                    div.d-flex.col-9.justify-center
                      just-eat-rating(v-if="item.code === 'JUS' && item.previous_period_site_rating" :ranking="item.previous_period_site_rating")
                      v-row.no-gutters.justify-center.items-center.no-bottom(v-else-if="item.code === 'GLV' && item.previous_period_count_positive")
                        div.d-flex.justify-center.items-center.mr-1
                          img.mr-0(:src="img.imgRatingGlovoRegular" style="width:15px;height:15px")
                          span.text-10 {{item.previous_period_count_positive}}
                        div.d-flex.justify-center.items-center
                          img.mr-0(:src="img.imgRatingBad" style="width:15px;height:15px")
                          span.text-10 {{item.previous_period_count_negative}}
                      div(v-else-if="item.code === 'UBE'")
                        img(:src="img.imgStar")
                        span.ml-4 {{ item.previous_period_site_rating | formatNumber }}
                      span(v-else) -
                    div.d-flex.col-3.justify-start
                      v-tooltip(right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
                        template(v-slot:activator="{ on, attrs }")
                          span.ml-4.mb-0.arrow(v-bind="attrs" v-on="on" :class="getArrow(item.previous_period_site_rating, item.previous_period_tend_site_rating)")
                        span(v-if="item.previous_period_tend_site_rating") {{ item.previous_period_tend_site_rating | formatNumber }}
                        span(v-else) -

    //GRID EVOLUTION MAIN NUMBER OPINIONS DELIVERY
    #boxGridEvolutionMain.margin-bottom-0.col-12
      skeleton-card(v-if="isLoading")
      v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card Anual Evolution of Average Rating and Number of Opinions
            span.ml-1.tDelivery Delivery
          .ml-auto.nav
            button.mr-5(@mousedown="setEvolutionOfAverage('MED','yearly')" :class="{ active: currentEvolutionOfAverage === 'MED' }") Media
            button.mr-5(v-if="deliverySitesStatus['JUS']" @mousedown="setEvolutionOfAverage('JUS','yearly')" :class="{ active: currentEvolutionOfAverage === 'JUS' }")
              img.mr-1(:src="img.imgJustEat")
              span.font-weight-medium Just Eat
            button.mr-5(v-if="deliverySitesStatus['UBE']" @mousedown="setEvolutionOfAverage('UBE','yearly')" :class="{ active: currentEvolutionOfAverage === 'UBE' }")
              img.mr-1(:src="img.imgUberEats")
              span.font-weight-medium Uber Eats

        div(v-if="dataEvolutionLastYearDelivery")
          box-evolution-main-number-opinion(:chartData="dataEvolutionLastYearDelivery" label="delivery-top" )
        //TODO: add missing translation
        div(v-else class="d-flex align-center justify-center py-16")
          span.font-weight-medium No hay data disponible

    #weeklyBoxGridEvolutionMain.margin-bottom-0.col-12
      skeleton-card(v-if="isLoading")
      v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card Weekly Evolution of Average Rating and Number of Opinions
            span.ml-1.tDelivery Delivery
          .ml-auto.nav
            button.mr-5(@mousedown="setEvolutionOfAverage('MED','weekly')" :class="{ active: weeklyCurrentEvolutionOfAverage === 'MED' }") Media
            button.mr-5(v-if="deliverySitesStatus['JUS']" @mousedown="setEvolutionOfAverage('JUS','weekly')" :class="{ active: weeklyCurrentEvolutionOfAverage === 'JUS' }")
              img.mr-1(:src="img.imgJustEat")
              span.font-weight-medium Just Eat
            button.mr-5(v-if="deliverySitesStatus['UBE']" @mousedown="setEvolutionOfAverage('UBE','weekly')" :class="{ active: weeklyCurrentEvolutionOfAverage === 'UBE' }")
              img.mr-1(:src="img.imgUberEats")
              span.font-weight-medium Uber Eats

        div(v-if="deliveryDataByRangeToRender")
          box-evolution-main-number-opinion(:chartData="deliveryDataByRangeToRender" label="delivery-bottom")
        //TODO: add missing translation
        div(v-else class="d-flex align-center justify-center py-16")
          span.font-weight-medium No hay data disponible

    //GRID % CATEGORÍAS POR MARCA DELIVERY
    div.margin-bottom-0.col-12
      skeleton-card(v-if="isLoading && !categories.length")
      v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading && categories.length")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card % Categories
            span.ml-1.tDelivery Delivery
          .block-help.ml-auto
            v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="20" nudge-left="10" content-class="tooltip-help tooltip-help-left")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              span {{helpCategoryBrand}}
            v-icon.mb-1(v-if="typeNode!=='group'" @click="goFeedback('Delivery')") mdi-chevron-right

        .card-content
          .row.text-left
            #tableCategoriasMarcas.tableGlobal
              v-col.col-12.headerTableRanking
                v-row.headerItem.items-center.justify-start.pl-4.pt-4
                  span.mr-1.chip-legend.positive
                  translate.mr-3 Positives
                  span.mr-1.chip-legend.neutral
                  translate.mr-3 Neutral
                  span.mr-1.chip-legend.negative
                  translate.mr-3 Negatives

              v-row.headerTableRanking.ma-0.no-gutters
                div.offset-1
                  span
                v-col.headerItem.justify-center(v-for="(categorie, indexc) in categories[0].delivery_categories" :key="indexc")
                  p.mb-0 {{ indexc }}

              v-row.contentTableRanking.no-gutters.h-auto
                p.ml-3(v-if="!categories") #[translate No data]
                div.itemContent.col-12.pa-0(v-for="(item, index) in categories" :key="index")
                  v-col.col-1.d-flex.justify-start
                    span.text-start(:class="{'link-text': index > 0}" @click="()=> index > 0 ? goItemNextLevel(item) : null") {{item.name}}
                  v-col.text-center(v-for="(category, index) in item.delivery_categories_sentiment_percent" :key="index")
                    v-row.no-gutters.pb-2
                      v-col.col-6
                        p.mb-0 {{ category.percent | formatNumber }} %
                      v-col.col-6
                        p.mb-0 {{ category.count | formatNumber }}
                    v-row.no-gutters
                      bar-three-colors(:percentGreen="category.positive" :percentOrange="category.neutral" :percentRed="category.negative" :render-details="true" :total="category.count")
                div.itemContent.col-12.pa-0(v-if="typeNode === 'center' && categories.length > 0 && categories[0].chain_data")
                  v-col.col-1
                    div.media-cadena.d-flex.justify-start
                      translate.text-start Chain mean
                  v-col.text-center(v-for="(category, index) in categories[0].chain_data.delivery_categories_sentiment_percent" :key="index")
                    v-row.no-gutters.pb-2
                      v-col.col-6
                        p.mb-0 {{ category.percent | formatNumber }} %
                      v-col.col-6
                        p.mb-0 {{ category.count | formatNumber }}
                    v-row.no-gutters
                      chart-bar-three-colors(:percentGreen="category.positive" :percentOrange="category.neutral" :percentRed="category.negative" :render-details="true" :total="category.count")

    //GRID EVOLUTION LAST YEAR CHART
    div.margin-bottom-0.col-12(v-if="dataEvolutionLastYearDeliveryGlobal && dataEvolutionLastYearDeliveryGlobal.n_reviews && dataEvolutionLastYearDeliveryGlobal.n_reviews.length > 0" )
      skeleton-card(v-if="isLoading")
      v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading && dataEvolutionLastYearDeliveryGlobal")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card Evolution of Sentiment on Websites of
            span.ml-1.tDelivery Delivery
          .block-help.ml-auto
            v-tooltip(left color="#222226" open-on-click=true max-width="550" content-class="tooltip-help tooltip-help-left")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              div
                p.mb-0.pb-0 En función de los filtros seleccionados, se muestra la evolución del Sentimiento del mes en curso y un año hacia atrás. Una vez analizadas las opiniones de los Agregadores obtenemos el sentimiento de las mismas, siendo los valores:
                ul
                  li #[translate Positives]: 10 – 7,5
                  li #[translate Neutral]: 7,5 - 5
                  li #[translate Negatives]: 5 - 0
                p.mb-0.pb-0 Se analiza sobre el grupo de restaurantes que marca la selección del menú izquierdo.
        box-evolution-last-year(:chartData="dataEvolutionLastYearDeliveryGlobal" :isDelivery="true" )


    //GRID COMPETENCIA
    grid-competencia.pa-3(:data="dataSummary" :isDelivery="true")

    //GRID COMPETENCIA LAST YEAR
    div.margin-bottom-0.col-12(v-if="hasCompetence && ['brand', 'center'].includes(typeNode)" )
      skeleton-card(v-if="isLoading")
      v-card.gridFull.ma-0.borderDelivery(v-if="!isLoading && dataSummary.length")
        .d-flex.headTitleGrid
          .block-title.titleGrid
            translate.mb-0.title-card Competence. Evolution of the number of opinions per restaurant
          .block-help.ml-auto
            v-tooltip(left color="#222226" open-on-click=true max-width="550" nudge-top="20" nudge-left="10" content-class="tooltip-help tooltip-help-left")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              div
                p.mb-0.pb-0 En función de los filtros seleccionados, se muestra la evolución de los datos de la marca frente a sus competidores:  Media de las notas de todos los restaurantes /nº de restaurantes.
        box-competence-last-year(:chartData="dataCompetenceLastYear")


</template>
<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import GridWebOpiniones from '@/components/global/WebOpiniones.vue'
import GridWebDelivery from '@/components/global/WebDelivery.vue'
import GridRankingRestaurantes from '@/components/global/RankingRestaurantes.vue'
import GridCompetencia from '@/components/global/Competencia.vue'
import GridCategoryCoverManager from '@/components/global/CategoryCoverManager.vue'
import ChartBarThreeColors from '@/components/charts/BarTrheeColors.vue'
import FeelingBars from '@/components/charts/FeelingBars.vue'
import BarThreeColors from '@/components/charts/BarTrheeColors.vue'
import BoxCompetenceLastYear from '@/components/network/CompetenceLastYear.vue'
import BoxEvolutionLastYear from '@/components/network/EvolutionLastYear.vue'
import BoxEvolutionMainNumberOpinion from '@/components/Delivery/EvolutionMainNumberOpinion.vue'
import SrvNetWork from '@/services/delivery.js'
import SrvGlobal from '@/services/global.js'
import commonMixins from '@/mixins/common.js'
import brandMixin from '@/mixins/brand.js'
import justEatRating from '@/components/common/justEatRating.vue'
import starRating from '@/components/common/starRating.vue'
import starRatingMini from '@/components/common/starRatingMini.vue'
import GlovoRating from '@/components/common/GlovoRating.vue'
import BoxSentimiento from '@/components/common/BoxSentimiento.vue'
import SwitchClose from '@/components/local/SwitchClose.vue';
import SwitchSync from '@/components/local/SwitchSync.vue';

export default {
  components: {BoxSentimiento, GlovoRating, starRating, starRatingMini, GridCategoryCoverManager, SkeletonCard, ChartBarThreeColors, GridWebOpiniones, GridWebDelivery, GridRankingRestaurantes, GridCompetencia, FeelingBars, BarThreeColors, BoxEvolutionLastYear, BoxEvolutionMainNumberOpinion, BoxCompetenceLastYear, SwitchClose, SwitchSync, justEatRating}, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  mixins: [commonMixins, brandMixin],
  data() {
    return {
      currentEvolutionOfAverage: 'MED',
      weeklyCurrentEvolutionOfAverage: 'MED',
      evolutionLastYearDeliveryData: {},
      deliverySitesStatus: {
        GLV: false,
        JUS: false,
        UBE: false
      },
      dateRanges: {},
      gridOpinions: 'display:grid;  grid-template-columns: 10% 8% 6% 6% 6% 6% 8% 6% 8% 8% 7% 6% 8% 7%; grid-auto-rows: max-content;',
      dataSummary: [],
      dataSummaryDelivery: [],
      dataEvolutionLastYearDelivery: [],
      deliveryDataByRange: [],
      deliveryDataByRangeToRender: [],
      dataEvolutionLastYearDeliveryGlobal: [],
      dataCompetenceLastYear: [],
      infoCoverManager: [],
      // Help Common
      helpCategoryBrand: this.$gettext('Percentages of the different categories that have been detected in the opinions of the selected period compared to the same period of the previous year (Tend). Use the Sites selector to see only your information. And the filter to segment. It is analyzed about the group of restaurants that marks the selection in the left menu and the filters applied.'),
      helpCategoryFeeling: this.$gettext('The company´s Internet opinions are analyzed using a semantic engine and the object that is commented on in each opinion and its evaluation is obtained. In this way, the feeling towards each of the categories that are analyzed is obtained. The same criteria are used as in the global analysis, compared to the same period of the previous year (P.A.). It is analyzed about the group of restaurants that marks the selection in the left menu and the filters applied.'),
      helpNOp: this.$gettext('Total number of opinions received from all the Websites during the selected period of the company and the different brands compared to the same period of the previous year (Tend). Use the website selector to see only the information on a specific website. You also have a filter of restaurant types for a more detailed analysis. It is analyzed about the group of restaurants that marks the selection in the left menu and the filters applied.'),
      dataCategories: [],
      img: {
        imgGlovo: require('@/assets/img/aggregators/glovo.svg'),
        imgUberEats: require('@/assets/img/aggregators/Uber Eats.png'),
        imgJustEat: require('@/assets/img/aggregators/Just Eat.png'),
        imgStar: require('@/assets/img/star.svg'),
        imgRatingBad: require('@/assets/img/glovo_rating_bad.svg'),
        imgRatingGlovoRegular: require('@/assets/img/rating_regular.png'),
      }
    }
  },
  created() {
    this.$store.dispatch('getAllSitesOpinions')
    if (this.$store.getters.getNode > 0) {
      this.getData()
    }
  },
  watch: {
    filterApply: function () {
      this.getData()
    }
  },
  computed: {
    categories() {
      const filteredData = []
      this.dataSummary.forEach(item => {
        const categories = item.delivery_categories_sentiment_percent
        if (categories.length > 0) {
          for (const category of categories) {
            if (category.count > 0 && category.percent > 0) {
              filteredData.push(item)
              break
            }
          }
        }
      })
      return filteredData
    }
  },
  methods: {
    getPercentCalculated(item, field, base) {
      if (item['delivery_rating'][field] && item['delivery_rating'][field]['site_rating']) {
        return (item['delivery_rating'][field]['site_rating'] > base ? base : item['delivery_rating'][field]['site_rating']) / base * 100
      } else {
        return 0
      }
    },
    getPercentCalculatedHistoric(item, field, base) {
      if (item['delivery_rating'][field] && item['delivery_rating'][field]['historical_site_rating']) {
        return (item['delivery_rating'][field]['historical_site_rating'] > base ? base : item['delivery_rating'][field]['historical_site_rating']) / base * 100
      } else {
        return 0
      }
    },
    formatNumber(val, min = 0, max = 1) {
      if (typeof (val) !== 'string') {
        if (val === null) {
          return '-'
        }
        return Number(val).toLocaleString('de-De', {minimumFractionDigits: min, maximumFractionDigits: max})
        // se deja en alemán para mostrar punto decimal en los miles return Number(val).toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
      }
      return val
    },
    setEvolutionOfAverage(type = 'MED', from) {
      let filter;
      let dataToRender;

      if (type === 'MED') {
        filter = this.evolutionLastYearDeliveryData
        dataToRender = this.deliveryDataByRange
      }

      if (type === 'JUS') {
        filter = this.evolutionLastYearDeliveryData['sites_delivery_data']['JUS']
        dataToRender = this.deliveryDataByRange['sites_delivery_data']['JUS']
      }

      if (type === 'UBE') {
        filter = this.evolutionLastYearDeliveryData['sites_delivery_data']['UBE']
        dataToRender = this.deliveryDataByRange['sites_delivery_data']['UBE']
      }

      if (from === "yearly") {
        this.dataEvolutionLastYearDelivery = filter
        this.currentEvolutionOfAverage = type
        return;
      }

      this.deliveryDataByRangeToRender = dataToRender
      this.weeklyCurrentEvolutionOfAverage = type
    },
    async getData() {
      this.$store.commit('LOADING', true)
      const res = await Promise.all([SrvNetWork.getEvolutionLastYear(), SrvNetWork.getEvolutionLastYearGlobal(),
        SrvNetWork.getGlobalInternet(), SrvNetWork.getGlobalDelivery(), SrvNetWork.getDeliveryByDateRange()])

      if (res[0].status === 200) {
        this.dataEvolutionLastYearDelivery = res[0].data
        this.evolutionLastYearDeliveryData = res[0].data
      }
      if (res[1].status === 200) {
        this.dataEvolutionLastYearDeliveryGlobal = res[1].data
      }
      if (res[2].status === 200) {
        /* ORDENAMOS ELEMENTOS SALVO EL PRIMERO*/
        const array1 = res[2].data.shift()
        const array2 = res[2].data
          .sort((a, b) => {
            // Old sort
            return a.name.localeCompare(b.name);
          })

        this.dataSummary = [].concat(array1, array2)
      }
      if (res[3].status === 200) {
        this.dataSummaryDelivery = res[3].data.data
        this.deliverySitesStatus = res[3].data.delivery_sites_status
        this.dateRanges = res[3].data.ranges
      }
      if (res[4].status === 200) {
        this.deliveryDataByRange = res[4].data
        this.deliveryDataByRangeToRender = res[4].data
      }
      if (this.showCoverManager) {
        const res = await SrvGlobal.getInfoCoverManager()
        if (res.status === 200) {
          this.infoCoverManager = res.data
        }
      }
      if (this.hasCompetence && ['brand', 'center'].includes(this.typeNode)) {
        const res = await SrvNetWork.getCompetenceLastYear()
        if (res.status === 200) {
          res.data.forEach((e) => e.show = true)
          this.dataCompetenceLastYear = res.data
        }
      }
      this.$store.commit('LOADING', false)
    }
    ,
    getColorProgressBarGPS(percent) {
      if (percent <= 0.50) {
        return 'progressRed'
      } else if (percent < 25.0) {
        return 'progressOrange'
      } else {
        return 'progressGreen'
      }
    }
    ,
    getWidthProgressBarGPS(percent) {
      return Math.abs(percent)
    }
    ,
  }
}
</script>
<style lang="scss" scoped>
#boxGridEvolutionMain {

  .headTitleGrid {
    border-bottom: 3px solid #F3F3F3;
    padding-bottom: 3px;

    .nav {
      font-size: 0.750rem;

      button {
        padding-bottom: 2px;
        padding: 0 15px 2px 15px;
        position: relative;

        &.active {
          font-weight: bold;

          &:after {
            position: absolute;
            border-bottom: 3px solid #F41938;
            bottom: -6px;
            content: ' ';
            left: 0;
            width: 100%;
          }

        }
      }

      img {
        height: 16px;
      }
    }
  }

  div:last-child > span {
    font-size: 13px;
  }
}

#weeklyBoxGridEvolutionMain {

  .headTitleGrid {
    border-bottom: 3px solid #F3F3F3;
    padding-bottom: 3px;

    .nav {
      font-size: 0.750rem;

      button {
        padding-bottom: 2px;
        padding: 0 15px 2px 15px;
        position: relative;

        &.active {
          font-weight: bold;

          &:after {
            position: absolute;
            border-bottom: 3px solid #F41938;
            bottom: -6px;
            content: ' ';
            left: 0;
            width: 100%;
          }

        }
      }
    }
  }

  div:last-child > span {
    font-size: 13px;
  }
}

#tableWebsDelivery {
  margin: 0 -10px -10px;

  .lastPeriod {
    font-size: 10px !important;
  }

  .emptyState {
    padding: 0px 10px;
  }

  .head {
    font-size: 0.75rem;
    border-bottom: 1px solid #E0E0E0;
    text-align: center;
  }

  .body {
    .col {
      display: flex;
      justify-content: center;
      padding: 5px 10px;
    }

    .row {
      font-size: 0.875rem;
      text-align: center;

      &.divider {
        border-bottom: 1px solid #E0E0E0;
        padding: 5px 0;
      }
    }
  }
}

#tableOpionnesYValoracionMedia {
  margin: 0 -10px -10px;

  .col {
    padding: 0;
    font-size: 0.875rem;
  }

  .row {
    margin: 0;
  }

  .colName {
    border-right: 1px solid #E0E0E0;
    display: flex;
    align-items: center;
  }

  .colValoracionMedia {
    text-align: center;

  }

  .colTotalOpiniones {
    border-right: 1px solid #E0E0E0;
  }


  .colJustEatOpiniones {
    border-right: 1px solid #E0E0E0;
  }

  .colGlovo {

    img {
      width: 25px;
    }

    // .glovoGrid {
    //   display: grid
    // }
  }

  .colGlovoOpiniones {
    border-right: 1px solid #E0E0E0;
  }


  .head {

    border-bottom: 1px solid #E0E0E0;

    .col {
      justify-content: center;
      font-size: 0.75rem;
      align-items: center;
      display: flex;
      padding-bottom: 10px;

      img {
        height: 23px;
      }
    }
  }

  .body {

    .row {
      .no-bottom {
        border-bottom: 0px
      }

      border-bottom: 1px solid #E0E0E0;

      .col {
        justify-content: center;
      }

      .boxHistorico {
        background-color: #F2F2F2;
        font-size: 10px;
        padding: 5px;
        min-height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .boxPeriodo {
        padding: 5px 15px;
        min-height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .colUberEats {
        img {
          width: 16px;
        }
      }

      .colGlovo,
      .colUberEats {
        img {
          margin-right: 5px;
        }
      }

      .colName {
        padding-left: 20px;
        justify-content: left;
      }

    }
  }

}

.colChartBrand {
  width: 10%;
}

.v-application #tableRanking .elevation-1 {
  box-shadow: none !important;
}


#tableValoracion {
  .col-name {
    max-width: 40.33%;
    flex: 0 0 40.33%;
    margin-right: 2.5%;
    padding-left: 10px;
    text-align: left;

    &.textBlackGroup {
      color: #000;
    }

    &.media-cadena {
      color: #979797;

      span {
        letter-spacing: -0.02px;
      }
    }
  }

  .col-nota {
    max-width: 18.47%;
    flex: 0 0 18.47%;
    margin-right: 4.5%;
  }

  .col-pa {
    max-width: 10.47%;
    flex: 0 0 10.47%;
    margin-right: 8.5%;
  }

  .col-tendencia {
    max-width: 10.47%;
    flex: 0 0 10.47%;
  }
}


#tableKantar {
  .col-name {
    max-width: 43.33%;
    flex: 0 0 43.33%;
    padding-left: 10px;
    text-align: left;

    &.media-cadena {
      color: #979797;

      span {
        letter-spacing: -0.02px;
      }
    }
  }

  .col-quiz {
    max-width: 23.33%;
    flex: 0 0 23.33%;
  }

  .col-satisfaccion {
    max-width: 31.33%;
    flex: 0 0 31.33%;
    margin-right: 2%;
  }
}

#tableCategoriasMarcas {
  &.tableCategoryFeeling {
    .headerItem {
      text-align: center;
      font-size: 0.75rem;
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  .contentTableRanking {
    .itemContent {
      min-height: 70px !important;
    }
  }

  .col-name {
    max-width: 25%;
    flex: 0 0 25%;
    padding-left: 10px;
    text-align: left;

    &.textBlackGroup {
      color: #000;
    }

    &.media-cadena {
      color: #979797;

      span {
        letter-spacing: -0.02px;
      }
    }
  }

  .centerChartTrheeColors {
    padding-top: 0;
    margin-top: -5px;
  }

  .col-numero {
    flex: auto;
    margin-right: 2%;
  }
}

#tableGPS {
  .col-name {
    max-width: 27.33%;
    flex: 0 0 27.33%;
    padding-left: 10px;
    text-align: left;

    &.textBlackGroup {
      color: #000;
    }

    &.media-cadena {
      color: #979797;

      span {
        letter-spacing: -0.02px;
      }
    }
  }

  .headerItem {
    &.col-progress {
      align-items: center;

      .ticksGpsPercent {
        font-size: 1rem;
        position: relative;
        width: 100%;

        .negative {
          position: absolute;
          left: 0;
          top: -13px;
        }

        .neutral {
          position: absolute;
          left: 50%;
          top: -13px;
          margin-left: 50%;
          left: -4.5px;
        }

        .positive {
          position: absolute;
          right: 0;
          top: -13px;
        }
      }

      /*div.headerItem.col-progress
                          .ticksGpsPercent
                              .negative -100
                              .neutral 0
                              .positive +100*/
    }
  }

  .col-progress {
    max-width: 35.93%;
    flex: 0 0 35.93%;

    .ticksGpsPercentBody {
      background-color: #E4E4E4;
      height: 12px;
      position: relative;
      width: 100%;

      .negative {
        position: absolute;
        left: 0;
        width: 50%;
        top: 0;
        direction: rtl;
      }

      .neutral {
        position: absolute;
        left: 50%;
        height: 24px;
        width: 1px;
        background-color: #707070;
        top: -6px;
        z-index: 1;
      }

      .positive {
        position: absolute;
        right: 0;
        width: 50%;
        top: 0;
      }

      .progressBar {
        height: 12px;

        &.progressRed {
          background-color: #D61A23;
        }

        &.progressOrange {
          background-color: #FCAE19;
        }

        &.progressGreen {
          background-color: #89C445;
        }
      }
    }


    .boxNote {
      width: 9%;
      margin-right: 1%;
      height: 19px;
      background-color: #ccc;
      color: #fff;
      text-align: center;
      display: inline-block;
      border-radius: 4px;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        background-color: #D61A23;
      }

      &:nth-child(7),
      &:nth-child(8) {
        background-color: #FCAE19;
      }

      &:nth-child(9),
      &:nth-child(10) {
        background-color: #89C445;
      }
    }
  }

  .col-media {
    max-width: 9.93%;
    flex: 0 0 9.93%;
    margin-right: 2%;
    justify-content: right;
  }

  .col-pa {
    max-width: 9.93%;
    flex: 0 0 9.93%;
    margin-right: 2%;
    justify-content: right;
  }

  .col-tend {
    max-width: 9.93%;
    flex: 0 0 9.93%;
    margin-right: 2%;
    justify-content: center;
  }
}

#tableEncuestasSatisfaccion {
  .col-name {
    max-width: 60%;
    flex: 0 0 60%;
    padding-left: 10px;
    text-align: left;
    color: #4A90E2;

    &.textBlackGroup {
      color: #000;
    }

    &.media-cadena {
      color: #979797;

      span {
        letter-spacing: -0.02px;
      }
    }
  }

  .col-numero {
    max-width: 19.93%;
    flex: 0 0 19.93%;
  }

  .col-tend {
    max-width: 19.93%;
    flex: 0 0 19.93%;
  }
}

#tableReclamaciones {
  .col-name {
    max-width: 60%;
    flex: 0 0 60%;
    padding-left: 10px;
    text-align: left;
    color: #4A90E2;

    &.textBlackGroup {
      color: #000;
    }

    &.media-cadena {
      color: #979797;

      span {
        letter-spacing: -0.02px;
      }
    }
  }

  .col-numero {
    max-width: 19.93%;
    flex: 0 0 19.93%;
  }

  .col-tend {
    max-width: 19.93%;
    flex: 0 0 19.93%;
  }
}
</style>
