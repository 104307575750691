<template lang="pug">
  div
    v-row.px-2.ma-0
      // LOGO MARCA

      header-center(:isLoading="isLoading" :dataProfile="dataProfile")

    #tableInfoActionsGoogle
      .head
        .colCanal {{ $gettext('Canal') }}
        .colPerfil {{ $gettext('Perfil') }}
        .colEstado {{ $gettext('Estado') }}
        .colFecha {{ $gettext('Fecha') }}
      .body
        .colCanal.d-flex
          img.mr-2(:src="img.googleMaps" height="37")
          div
            span.mb-0 Google
            br
            a.link-text.text-14(target="_blank" v-if="dataProfile.socialaccounts.google_map_url" :href="dataProfile.socialaccounts.google_map_url") Ver en Google
        .colPerfil
          div.chartPercentCircle.mt-2.mb-3(:style="getColorChartPercent(dataProfile ? dataProfile.profile_completed : 0)")
            p {{ dataProfile ? dataProfile.profile_completed : 0 | formatNumber(0,0) }}%
        #channels.colEstado.automaticSync.d-flex
          div
            p.titleSync.mb-1 #[translate Automatic sync]
            p.mb-1 #[translate State]:
              span.stateAccountChannel(v-if="dataProfile.accounts.google.status" :class="getClassStateAndLinked(dataProfile.accounts.google.status_colour)") {{ dataProfile.accounts.google.status }}
              span.stateAccountChannel.stateKo(v-else) -
                //span.info-text(v-if="dataProfile.automatic_sync") #[translate Active]
                //span.info-text(v-else) #[translate Disabled]
          div
            template(v-if="verifyTokenGoogle")
              button.buttonConfig.google.opacity-50(disabled="true")
                div.d-flex.align-center
                  .box.ml-2
                    img(:src="img.googleMaps")
                  span.pl-2 Verifing
            template(v-else-if="!hasTokenGoogle")
              button.buttonConfig.google(@click="openAuthGoogle")
                div.d-flex.align-center
                  .box.ml-2
                    img(:src="img.googleMaps")
                  span.pl-2 Get Token
            template(v-else)
              button.buttonConfig.google(v-if="$config.featureConfig['googleSetupButtonInProfile']" @click="openGoogleLink = true")
                div.d-flex.align-center
                  .box.ml-2
                    img(:src="img.googleMaps")
                  span.pl-2 Set up
          div
            button.buttonConfig.google.unlink(v-if="dataProfile.accounts.google.status && isStaff" @click="unlinkCenterGoogle()")
              div.d-flex.align-center
                .box.ml-2
                  img(:src="img.googleMaps")
                span.pl-2 Desconectar
        .colFecha.d-flex.automaticSync.gap-2em.align-center
          div
            p.mb-1 #[translate  Creation date]:
              span.info-text {{dataProfile ? dataProfile.created : null | moment("DD/MM/YYYY") }}
            p.mb-1 #[translate Last sync]:
              span.info-text {{dataProfile ? dataProfile.last_sync : null | moment("DD/MM/YYYY") }}
          div.d-flex.gap-2em.align-center
            div(v-if="showEdit")
              button.btnSyncExportGoogle(@click="sync(dataProfile ? dataProfile.id : null)") #[translate Sync now]
                div.gr-loader-snipper--wrapper(v-if="loadingSync")
                  span.gr-loader-snipper
                br
                span #[translate (export data)]
            div(v-if="showEdit")
              button.btnSyncExportGoogle(@click="importGoogle(dataProfile ? dataProfile.id : null)")
                div.gr-loader-snipper--wrapper(v-if="loadingImportGoogle")
                  span.gr-loader-snipper
                span Importar datos
                br
                | de Google

    v-row.px-2.ma-0
      .col-7
        .row
          div#centerData.margin-bottom-0.col-12
            skeleton-card(v-if="isLoading")
            v-card.gridFull.ma-0.pl-6.pr-6.pb-4(v-if="!isLoading")
              v-row.headTitleGrid
                v-col.titleGrid(md="6")
                  span(v-if="!isGenericEntity") #[translate Restaurant data]
                  span(v-else) Datos del negocio
                v-col.text-right(md="6" v-if="showEdit")
                  button.btnEditar(@click.stop="showEditData = true")
                    span #[translate Edit]
                    img(:src="img.edit")
              v-row
                v-col.pb-1(md="12")
                  p.mb-0
                    span(v-if="!isGenericEntity" :class="{'indicatorNotInfo': !dataProfile.name}") #[translate Name of the restaurant]
                    span(v-else :class="{'indicatorNotInfo': !dataProfile.name}") Nombre del negocio
                  span.info-text.ml-2.d-inline-block(v-if="dataProfile.name") {{ dataProfile.name }}
                  span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  p.mb-0
                    span(:class="{'indicatorNotInfo': !dataProfile.street_address}") #[translate Full address]
                  span.info-text.ml-2.d-inline-block(v-if="dataProfile.street_address") {{ dataProfile.street_address }}, {{ dataProfile.postal_code }} {{ dataProfile.province }}
                  span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  p.mb-0
                    span(:class="{'indicatorNotInfo': !dataProfile.phone}") #[translate Telephone]
                  span.info-text.ml-2.d-inline-block(v-if="dataProfile.phone") {{ dataProfile.phone }}
                  span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  p.mb-0
                    span(:class="{'indicatorNotInfo': !dataProfile.email}") #[translate Email]
                  span.info-text.ml-2.d-inline-block(v-if="dataProfile.email") {{ dataProfile.email }}
                  span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  p.mb-0
                    span(:class="{'indicatorNotInfo': dataProfile.googlePrimaryCategory && !dataProfile.googlePrimaryCategory.name}") #[translate Bussiness category]
                  span.info-text.ml-2.d-inline-block(v-if="dataProfile.googlePrimaryCategory && dataProfile.googlePrimaryCategory.name") {{ dataProfile.googlePrimaryCategory.name }} #[strong Principal]
                  span.info-text.ml-2(v-else) -
                  p.mb-0(v-for="(extraCat, index) in dataProfile.googleAdditionalCategories")
                    span.info-text.ml-2 {{  extraCat.name }}
                v-col.pb-1(md="12")
                  p.mb-0
                    span(:class="{'indicatorNotInfo': !dataProfile.url}") #[translate Website]
                  span.ml-2.d-inline-block(v-if="dataProfile.url")
                    a.link-text(:href="dataProfile.url" target="_blank") {{ dataProfile.url }}
                  span.info-text.ml-2(v-else) -
                v-col(md="12")
                  p.mb-0
                    span(:class="{'indicatorNotInfo': !dataProfile.price_range}") #[translate Price range]
                  span.info-text.ml-2.d-inline-block(v-if="dataProfile.price_range && dataProfile.price_range.value") {{ dataProfile.price_range.value }}
                  span.info-text.ml-2(v-else) -

          div#centerDetails.margin-bottom-0.col-12
            skeleton-card(v-if="isLoading")
            v-card.gridFull.ma-0.pl-6.pr-6.pb-4(v-if="!isLoading")
              v-row.headTitleGrid
                v-col.titleGrid(md="6")
                  span(v-if="!isGenericEntity") #[translate Restaurant details]
                  span(v-else) Detalles del negocio
                v-col.text-right(md="6" v-if="showEdit")
                  button.btnEditar(@click="showEditDetails = true")
                    span #[translate Edit]
                    img(:src="img.edit")
              v-row
                v-col.pb-1(md="12")
                  p.mb-0
                    span(:class="{'indicatorNotInfo': !dataProfile.short_description}") #[translate Short description]
                  span.info-text.ml-2.d-inline-block(v-if="dataProfile.short_description") {{ dataProfile.short_description }}
                  span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  p.mb-0
                    span(:class="{'indicatorNotInfo': !dataProfile.long_description}") #[translate Long description]
                  span.info-text.ml-2.d-inline-block(v-if="dataProfile.long_description") {{ dataProfile.long_description }}
                  span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  p.mb-0
                    span(:class="{'indicatorNotInfo': !dataProfile.keywords}") #[translate Keywords that stand out from the business]
                  span.info-text.ml-2(v-if="dataProfile.keywords") {{ dataProfile.keywords }}
                  span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  p.mb-0
                    span(:class="{'indicatorNotInfo': !dataProfile.languages}") #[translate Languages served]
                  span.info-text.ml-2(v-if="dataProfile.languages") {{ dataProfile.languages }}
                  span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  p.mb-0
                    span(:class="{'indicatorNotInfo': !dataProfile.special_services}") #[translate Special services]
                  span.info-text.ml-2(v-if="dataProfile.special_services") {{ dataProfile.special_services }}
                  span.info-text.ml-2(v-else) -
                v-col.pb-1.colPickup(md="12" v-if="dataProfile && dataProfile.can_have_food_menus && !isGenericEntity")
                  p.mb-0
                    span #[translate Pick-up service at the establishment]
                    span.info-text.ml-2(v-if="dataProfile.has_pickup")
                      span #[translate Yes]
                      img(:src="img.active")
                    span.info-text.ml-2(v-else)
                      span #[translate No]
                      img(:src="img.disabled")
                v-col.colDelivery(md="12" v-if="dataProfile && dataProfile.can_have_food_menus && !isGenericEntity")
                  p.mb-0
                    span #[translate Home delivery service]
                    span.info-text.ml-2(v-if="dataProfile.has_delivery")
                      span #[translate Yes]
                      img(:src="img.active")
                    span.info-text.ml-2(v-else)
                      span #[translate No]
                      img(:src="img.disabled")
                v-col.colDeliveryServices(md="12" v-if="dataProfile.center_sites_delivery && !isGenericEntity")
                  v-row
                    v-col(md="6")
                      v-row
                        .boxDeliveryService(:class="{noService: !dataProfile.own_delivery_service}")
                          v-col.pb-0(md="12")
                            img.d-inline-block(v-if="dataProfile.own_delivery_service" :src="img.active" width="29")
                            img.d-inline-block(v-else :src="img.disabled" width="29")
                            span.ml-2.d-inline-block Propio
                          v-col.urlDeliveryService(md="12")
                            a.link-text(v-if="dataProfile.url_own_delivery" target="_blank" :href="dataProfile.url_own_delivery") {{ dataProfile.url_own_delivery }}
                            span(v-else) https://
                    v-col(md="6")
                      v-row
                        .boxDeliveryService(:class="{noService: !dataProfile.other_delivery_service}")
                          v-col.pb-0(md="12")
                            img.d-inline-block(v-if="dataProfile.other_delivery_service" :src="img.active" width="29")
                            img.d-inline-block(v-else :src="img.disabled" width="29")
                            img.ml-2.d-inline-block(:src="img.deliveryOtro" width="29")
                            span.ml-2.d-inline-block Otro
                          v-col.urlDeliveryService(md="12")
                            a.link-text(v-if="dataProfile.url_other_delivery" target="_blank" :href="dataProfile.url_other_delivery") {{ dataProfile.url_other_delivery }}
                            span(v-else) https://
                    v-col(md="6")
                      v-row(v-if="dataProfile.center_sites_delivery.delivery_sites.GLV")
                        .boxDeliveryService(:class="{noService: !dataProfile.center_sites_delivery.delivery_sites.GLV.active}")
                          v-col.pb-0(md="12")
                            img.d-inline-block(v-if="dataProfile.center_sites_delivery.delivery_sites.GLV.active" :src="img.active" width="29")
                            img.d-inline-block(v-else :src="img.disabled" width="29")
                            img.ml-2.d-inline-block(:src="img.deliveryGlovo" width="64")
                          v-col.urlDeliveryService(md="12")
                            a.link-text(v-if="dataProfile.center_sites_delivery.delivery_sites.GLV.url" target="_blank" :href="dataProfile.center_sites_delivery.delivery_sites.GLV.url") {{ dataProfile.center_sites_delivery.delivery_sites.GLV.url }}
                            span(v-else) https://
                    v-col(md="6")
                      v-row(v-if="dataProfile && dataProfile.can_have_food_menus && dataProfile.center_sites_delivery.delivery_sites.JUS")
                        .boxDeliveryService(:class="{noService: !dataProfile.center_sites_delivery.delivery_sites.JUS.active}")
                          v-col.pb-0(md="12")
                            img.d-inline-block(v-if="dataProfile.center_sites_delivery.delivery_sites.JUS.active" :src="img.active" width="29")
                            img.d-inline-block(v-else :src="img.disabled" width="29")
                            img.ml-2.d-inline-block(:src="img.deliveryJusteat" width="64")
                          v-col.urlDeliveryService(md="12")
                            a.link-text(v-if="dataProfile.center_sites_delivery.delivery_sites.JUS.url" target="_blank" :href="dataProfile.center_sites_delivery.delivery_sites.JUS.url") {{ dataProfile.center_sites_delivery.delivery_sites.JUS.url }}
                            span(v-else) https://
                    //v-col(md="6")
                      v-row
                        .boxDeliveryService(:class="{noService: !dataProfile.center_sites_delivery.delivery_sites.DEL.active}")
                          v-col.pb-0(md="12")
                            img.d-inline-block(v-if="dataProfile.center_sites_delivery.delivery_sites.DEL.active" :src="img.active" width="29")
                            img.d-inline-block(v-else :src="img.disabled" width="29")
                            img.ml-2.d-inline-block(:src="img.deliveryDeliveroo" width="68")
                          v-col.urlDeliveryService(md="12")
                            a.link-text(v-if="dataProfile.center_sites_delivery.delivery_sites.DEL.url" target="_blank" :href="dataProfile.center_sites_delivery.delivery_sites.DEL.url") {{ dataProfile.center_sites_delivery.delivery_sites.DEL.url }}
                            span(v-else) https://
                    v-col(md="6")
                      v-row(v-if="dataProfile && dataProfile.can_have_food_menus && dataProfile.center_sites_delivery.delivery_sites.UBE")
                        .boxDeliveryService(:class="{noService: !dataProfile.center_sites_delivery.delivery_sites.UBE.active}")
                          v-col.pb-0(md="12")
                            img.d-inline-block(v-if="dataProfile.center_sites_delivery.delivery_sites.UBE.active" :src="img.active" width="29")
                            img.d-inline-block(v-else :src="img.disabled" width="29")
                            img.ml-2.d-inline-block(:src="img.deliveryUbereats" width="48")
                          v-col.urlDeliveryService(md="12")
                            a.link-text(v-if="dataProfile.center_sites_delivery.delivery_sites.UBE.url" target="_blank" :href="dataProfile.center_sites_delivery.delivery_sites.UBE.url") {{ dataProfile.center_sites_delivery.delivery_sites.UBE.url }}
                            span(v-else) https://
          div#galery.margin-bottom-0.col-12
            skeleton-card(v-if="isLoading")
            v-card.gridFull.ma-0.pl-6.pr-6.pb-6(v-if="!isLoading")
              v-row.headTitleGrid
                v-col.titleGrid(md="6")
                  span(v-if="!isGenericEntity") #[translate Restaurant images]
                  span(v-else) Imágenes del negocio
                v-col.text-right(md="6" v-if="showEdit")
                  button.btnEditar(@click="showEditPhotos = true")
                    span #[translate Edit]
                    img(:src="img.edit")
              v-row
                v-col.pb-1(md="12")
                  v-row(v-if="dataProfile.googlephotos")
                    v-col(md="2" v-for="photo in dataProfile.googlephotos")
                      .photo
                        p.mb-2.categoryPhoto {{ photo.category }}
                        img(:src="photo.photo_thumbnail_url")
                    v-col(md="2")
                      button.buttonAddPhoto(v-if="1!==1")
                        img(:src="img.addPhoto")
                  v-else
                    span.text-12.without-text No hay data


      .col-5
        .row
          div#location.margin-bottom-0.col-12
            skeleton-card(v-if="isLoading")
            v-card.gridFull.ma-0(v-if="!isLoading")
              v-row.headTitleGrid.pl-5.pr-4
                v-col.titleGrid(md="6")
                  span #[translate Location]
                v-col.text-right(md="6" v-if="1!==1 && showEdit")
                  button.btnEditar(@click="showEditLocation = true")
                    span #[translate Edit]
                    img(:src="img.edit")
              v-row
                div(v-if="dataProfile.longitude && dataProfile.latitude" style="width:100%")
                  map-center(:long="dataProfile.longitude" :lat="dataProfile.latitude")
                img.noMap(v-else :src="img.noMap")
          div#horarios.margin-bottom-0.col-12
            skeleton-card(v-if="isLoading")
            v-card.gridFull.ma-0.pl-6.pr-6.pb-6(v-if="!isLoading")
              v-row.headTitleGrid
                v-col.titleGrid(md="6")
                  span #[translate Opening times]
                v-col.text-right(md="6" v-if="showEdit")
                  //button.btnEditar(@click="showEditSchedules = true")
                    span #[translate Edit]
                    img(:src="img.edit")

              v-row(v-if="dataProfile")
                v-col.pb-1.d-flex(md="12")
                  strong.flex-1 #[translate Horario Abierto (con horario especificado)]
                  button.btnEditar(@click="editSchedules(null)")
                    span #[translate Edit]
                    img(:src="img.edit")
                v-col.pb-1(md="12" v-for="(dayNormal, indexNormal) in Object.keys(dataProfile.googleopenday)")
                  v-row
                    v-col(md="4")
                      p.mb-0.day {{ dataProfile.googleopenday[dayNormal].name }}
                    v-col.text-right(md="8" v-if="!dataProfile.googleopenday[dayNormal].isclosed")
                      span.ml-2(v-for="hor in dataProfile.googleopenday[dayNormal].times")
                        span {{ hor.starttime }} - {{ hor.endtime }}
                    v-col.text-right(v-else)
                      span.ml-2.stateKo #[translate Closed]

              v-row(:class="{'premiumDisabled': !hasPremium}" v-for="(houerType, indexHouerType) in dataProfile.googleextraopendaytype")
                v-col.pb-1.d-flex(md="12")
                  strong.flex-1 {{ houerType.hour_type }}
                  button.btnEditar(@click="editSchedules(houerType.hour_type)")
                    span #[translate Edit]
                    img(:src="img.edit")
                v-col.pb-1(md="12" v-for="(day, index) in Object.keys(houerType.days)")
                  v-row
                    v-col(md="4")
                      p.mb-0.day {{ houerType.days[day].name }}
                    v-col.text-right(md="8" v-if="!houerType.days[day].isclosed")
                      span.ml-2(v-for="hor in houerType.days[day].times")
                        span {{ hor.starttime }} - {{ hor.endtime }}
                    v-col.text-right(v-else)
                      span.ml-2.stateKo #[translate Closed]
              hour-types(v-if="dataProfile && dataProfile.can_have_food_menus" :hasPremium="hasPremium" :dataProfile="dataProfile" @onCreateSchedul="addNewHours")
          div#social.margin-bottom-0.col-12
            skeleton-card(v-if="isLoading")
            v-card.gridFull.ma-0.pl-6.pr-6.pb-4(v-if="!isLoading")
              v-row.headTitleGrid
                v-col.titleGrid(md="6")
                  span #[translate Social media]
                v-col.text-right(md="6" v-if="showEdit")
                  button.btnEditar(@click="showEditSocial = true")
                    span #[translate Edit]
                    img(:src="img.edit")
              v-row
                v-col.pb-1(md="12")
                  v-row
                    v-col(md="12")
                      .icoSocial
                        img(:src="img.web")
                      p.mb-0
                        span(:class="{'indicatorNotInfo': !dataProfile.url}") #[translate URL web]
                      span.ml-2(v-if="dataProfile.url")
                        a.link-text(:href="dataProfile.url" target="_blank") {{ dataProfile.url }}
                      span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  v-row
                    v-col(md="12")
                      .icoSocial
                        img(:src="img.bing")
                      p.mb-0
                        span(:class="{'indicatorNotInfo': !dataProfile.socialaccounts.bing_maps_url}") #[translate URL Bing]
                      span.ml-2(v-if="dataProfile.socialaccounts.bing_maps_url")
                        a.link-text(:href="dataProfile.socialaccounts.bing_maps_url" target="_blank") {{ dataProfile.socialaccounts.bing_maps_url }}
                      span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  v-row(v-if="dataProfile.socialaccounts")
                    v-col(md="12")
                      .icoSocial
                        img(:src="img.facebook")
                      p.mb-0
                        span(:class="{'indicatorNotInfo': !dataProfile.socialaccounts.facebook_url}") #[translate URL Facebook]
                      span.ml-2(v-if="dataProfile.socialaccounts.facebook_url")
                        a.link-text(:href="dataProfile.socialaccounts.facebook_url" target="_blank") {{ dataProfile.socialaccounts.facebook_url }}
                      span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  v-row(v-if="dataProfile.socialaccounts")
                    v-col(md="12")
                      .icoSocial
                        img(:src="img.instagram")
                      p.mb-0
                        span(:class="{'indicatorNotInfo': !dataProfile.socialaccounts.instagram_url}") #[translate URL Instagram]
                      span.ml-2(v-if="dataProfile.socialaccounts.instagram_url")
                        a.link-text(:href="dataProfile.socialaccounts.instagram_url" target="_blank") {{ dataProfile.socialaccounts.instagram_url }}
                      span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  v-row(v-if="dataProfile.socialaccounts")
                    v-col(md="12")
                      .icoSocial
                        img(:src="img.twitter")
                      p.mb-0
                        span(:class="{'indicatorNotInfo': !dataProfile.socialaccounts.twitter_url}") #[translate URL Twitter]
                      span.ml-2(v-if="dataProfile.socialaccounts.twitter_url")
                        a.link-text(:href="dataProfile.socialaccounts.twitter_url" target="_blank") {{ dataProfile.socialaccounts.twitter_url }}
                      span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  v-row(v-if="dataProfile.socialaccounts")
                    v-col(md="12")
                      .icoSocial
                        img(:src="img.youtube")
                      p.mb-0
                        span(:class="{'indicatorNotInfo': !dataProfile.youtube_url}") #[translate URL Youtube]
                      span.ml-2(v-if="dataProfile.socialaccounts.youtube_url")
                        a.link-text(:href="dataProfile.socialaccounts.youtube_url" target="_blank") {{ dataProfile.socialaccounts.youtube_url }}
                      span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  v-row.mb-0
                    v-col(md="12")
                      .icoSocial
                        img(:src="img.linkedin")
                      p.mb-0
                        span(:class="{'indicatorNotInfo': !dataProfile.socialaccounts.linkedin_url}") URL Linkedin
                      span.ml-2(v-if="dataProfile.socialaccounts.linkedin_url")
                        a.link-text(:href="dataProfile.socialaccounts.linkedin_url" target="_blank") {{ dataProfile.socialaccounts.linkedin_url }}
                      span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  v-row.mb-0
                    v-col(md="12")
                      .icoSocial
                        img(:src="img.tiktok")
                      p.mb-0
                        span(:class="{'indicatorNotInfo': !dataProfile.socialaccounts.tiktok_url}") URL TikTok
                      span.ml-2(v-if="dataProfile.socialaccounts.tiktok_url")
                        a.link-text(:href="dataProfile.socialaccounts.tiktok_url" target="_blank") {{ dataProfile.socialaccounts.tiktok_url }}
                      span.info-text.ml-2(v-else) -
                v-col.pb-1(v-if="!isAdverbia" md="12")
                  v-row
                    v-col(md="12")
                      .icoSocial
                        img(:src="img.carta")
                      p.mb-0
                        span(:class="{'indicatorNotInfo': !dataProfile.menu_url}") #[translate Letter url]
                      span.ml-2(v-if="dataProfile.menu_url")
                        a.link-text(:href="dataProfile.menu_url" target="_blank") {{ dataProfile.menu_url }}
                      span.info-text.ml-2(v-else) -
                v-col.pb-1(md="12")
                  v-row.mb-0
                    v-col(md="12")
                      .icoSocial
                        img(:src="img.reservas")
                      p.mb-0
                        span(:class="{'indicatorNotInfo': !dataProfile.booking_url}") #[translate Booking engine URL]
                      span.ml-2(v-if="dataProfile.booking_url")
                        a.link-text(:href="dataProfile.booking_url" target="_blank") {{ dataProfile.booking_url }}
                      span.info-text.ml-2(v-else) -
          div#galery.margin-bottom-0.col-12
            skeleton-card(v-if="isLoading")
            v-card.gridFull.ma-0.pl-6.pr-6.pb-6(v-if="!isLoading")
              v-row.headTitleGrid
                v-col.titleGrid(md="6")
                  span(v-if="!isGenericEntity") #[translate Restaurant videos]
                  span(v-else) Videos del negocio
                v-col.text-right(md="6" v-if="showEdit")
                  button.btnEditar(@click="showEditVideos = true")
                    span #[translate Edit]
                    img(:src="img.edit")
              v-row
                v-col.pb-1(md="12")
                  v-row(v-if="dataProfile.googlevideos")
                    v-col(md="2" v-for="video in dataProfile.googlevideos")
                      .photo
                        img(:src="video.video_thumbnail_url")
                    v-col(md="2")
                  v-else
                    span.text-12.without-text No hay data

      #groupQueOfrece
        div.d-flex.flex-wrap(v-if="dataProfile && dataProfile.bool_attributes && dataProfile.bool_attributes.length > 0" :class="{'premiumDisabled': !hasPremium}")
          .col-12
            .ma-0.pl-6.pr-6.pb-4.dialogEdit
              .body
                v-row.pt-3
                  v-col.py-1.d-flex.align-center(md-12)
                    <img :src="img.googleMaps" />
                    p.ml-2.mb-0 Muestra atributos en tu #[strong Perfil de Empresa] para que los clientes la conozcan mejor. Pueden aparecer públicamente en la Búsqueda.<br /> Maps y otros servicios de Google.

          que-ofrece.col-4(v-for="(group, index) in boolAttributesGroups" :hasPremium="hasPremium" :bool_attributes="dataProfile.bool_attributes.filter((e) => e.group_name === group.key)" @update:bool_attributes="updateBoolAttributes")
      .col-12
        .row
          div#channels.margin-bottom-0.col-12
            skeleton-card(v-if="isLoading")
            v-card.gridFull.ma-0(v-if="!isLoading")
              v-row.headTitleGrid
                v-col.titleGrid(md="3")
                  span #[translate Channels]
                v-col.titleGrid(md="2")
                  span #[translate Profile]
                v-col.titleGrid(md="3")
                  span #[translate State]
                v-col.titleGrid(md="2")
                  span #[translate Date]
                v-col.titleGrid.text-center(md="2")
                  span #[translate Sincronización on/off]
              v-row.rowChannel(v-if="dataProfile.accounts && dataProfile.accounts.facebook")
                v-col.colIcoChannel(md="3")
                  v-row
                    v-col(sm="2")
                      img(:src="img.facebook")
                    v-col(sm="10")
                      .nameChannel  Facebook
                v-col(md="2")
                  div
                    .chartMiniPie(:style="getColorMiniChartPie(dataProfile.facebook_profile_completed)")
                    span.percentNumber.ml-2 {{dataProfile.facebook_profile_completed | formatNumber(0,0)}}%
                  //.accountChannel #[translate Account]
                  //.stateAccountChannel(v-if="dataProfile.accounts.facebook.linked" :class="getClassStateAndLinked(dataProfile.accounts.facebook.linked_colour)") {{ dataProfile.accounts.facebook.linked }}
                  //.stateAccountChannel.stateKo(v-else) -
                v-col(md="3")
                  v-row
                    v-col(sm="6")
                      span.stateAccountChannel(v-if="dataProfile.accounts.facebook.status" :class="getClassStateAndLinked(dataProfile.accounts.facebook.status_colour)")  {{ dataProfile.accounts.facebook.status }}
                      span.stateAccountChannel.stateKo(v-else) -
                    v-col(sm="6")
                      button.buttonConfig.facebook(v-if="$config.featureConfig['facebookSetupButtonInProfile']" @mousedown="facebookSetup")
                        img(:src="img.facebookWhite")
                        span #[translate Set up]
                v-col(md="2")
                  .titleLastSync #[translate Last sync date]
                  .dataLastSync {{ dataProfile.accounts.facebook.last_token_updated | moment("DD/MM/YYYY") }}
                v-col.text-right(md="2")

              // OTROS CANALES
              v-row.rowChannel(v-for="(site, index) in sitesList" :class="{'notExtended': !localMarketingExtended}")
                v-col.colIcoChannel(md="3")
                  v-row
                    v-col(sm="2")
                      img(:src="getImageSiteLocalMark(site.site_code)")
                    v-col(sm="10")
                      .nameChannel {{ site.site_name }}
                v-col.titleGrid(md="2")
                  div
                    .chartMiniPie(:style="getColorMiniChartPie(site.percent_completed)")
                    span.percentNumber.ml-2 {{site.percent_completed | formatNumber(0,0)}}%
                v-col.titleGrid(md="3")
                  v-row
                    v-col(sm="6")
                      span.stateAccountChannel(v-if="site.status === 'disconnected'") {{ $gettext('Offline') }}
                      span.stateAccountChannel(v-else-if="site.status === 'validate'") {{ $gettext('Validating') }}
                      span.stateAccountChannel(v-else) #[translate Synchronised]
                    v-col(sm="6")
                      button.buttonConfig.connect(v-if="site.status === 'disconnected'" @click="connectSiteLocalMarketing(site.site_code)" :class="{'cursor-inherit': !localMarketingExtended}")
                        .box.ml-2
                          img(:src="getImageSiteLocalMark(site.site_code)" width="18")
                        span.pl-4 {{ $gettext('Connect') }}
                      button.buttonConfig.validate(v-else-if="site.status === 'validate'" :class="{'cursor-inherit': !localMarketingExtended}")
                        .box.ml-2
                          img(:src="getImageSiteLocalMark(site.site_code)" width="18")
                        span.pl-4 {{ $gettext('Validating') }}
                      button.buttonConfig.update(v-else @click="updateSiteLocalMarketing(site.site_code, site.id)" :class="{'cursor-inherit': !localMarketingExtended}")
                        .box.ml-2
                          img(:src="getImageSiteLocalMark(site.site_code)" width="18")
                        span.pl-4 #[translate Update]
                v-col(md="2")

                  .titleLastSync.validate(v-if="site.status === 'validate'") #[translate In the process of validation]
                  .titleLastSync(v-else) #[translate Last sync date]
                  .titleLastSync(:class="{'validate': site.status === 'validate'}" v-if="site.date_updated") {{ site.date_updated | moment("DD/MM/YYYY") }}
                  .titleLastSync(:class="{'validate': site.status === 'validate'}" v-else) -
                v-col.text-center(md="2")
                  switch-sync(:sync="site.sync" :pos="index" :id="site.id" @changeSync="changeSync" :status="site.status" :key="index" :isExtended="localMarketingExtended")

    //MODALES EDICION
    edit-data(:visible="showEditData"  @close="showEditData=false" :idBussines="dataProfile ? dataProfile.id : null" @refreshSync="getLocalMarketing")
    edit-details(:visible="showEditDetails" :hasMenu="dataProfile ? dataProfile.can_have_food_menus : false"  @close="showEditDetails=false" :idBussines="dataProfile ? dataProfile.id : null" @refreshSync="getLocalMarketing")
    edit-location(:visible="showEditLocation"  @close="showEditLocation=false" :idBussines="dataProfile ? dataProfile.id : null" @refreshSync="getLocalMarketing")
    edit-social(:visible="showEditSocial"  @close="showEditSocial=false" :idBussines="dataProfile ? dataProfile.id : null" @refreshSync="getLocalMarketing")
    edit-schedules(:visible="showEditSchedules" :type="showEditSchedulesType"  @close="showEditSchedules=false" :idBussines="dataProfile ? dataProfile.id : null" @refreshSync="getLocalMarketing")
    edit-photos(:visible="showEditPhotos"  @close="showEditPhotos=false" :idBussines="dataProfile ? dataProfile.id : null" @refreshSync="getLocalMarketing")
    edit-videos(:visible="showEditVideos"  @close="showEditVideos=false" :idBussines="dataProfile ? dataProfile.id : null" @refreshSync="getLocalMarketing")
    facebook-locations(v-if="facebookConfig.app_id && center" :selected.sync="facebookSelected"
      :center.sync="center" :current-facebook-linkeded="currentFacebookLinkeded"
      :app-id="facebookConfig.app_id" :api-version="facebookConfig.api_version"
      :open.sync="openFacebookLocations"
      @updateProfileIdFacebook="updateProfileIdFacebook")
    google-link(:open.sync="openGoogleLink" @update="updateListGoogle" @refreshPage="refreshPage")
    show-error(:visible="showError"  @close="showError=false" :message="messageError")
</template>
<script>
import SkeletonCard from "@/components/common/skeletonCard.vue";
import SrvLocal from "@/services/local.js";
import commonMixins from "@/mixins/common.js";
import mapCenter from "@/components/local/map.vue";
import showError from "@/components/local/showError.vue";
//FICHAS EDICION
import editData from "@/components/local/editData.vue";
import editDetails from "@/components/local/editDetails.vue";
import editLocation from "@/components/local/editLocation.vue";
import editSocial from "@/components/local/editSocial.vue";
import editSchedules from "@/components/local/editSchedules.vue";
import editPhotos from "@/components/local/editPhotos.vue";
import editVideos from "@/components/local/editVideos.vue";
import GoogleLink from "@/components/local/GoogleLink.vue";
import SrvGlobal from "@/services/local";
import FacebookLocations from "@/components/local/FacebookLocations";
import SwitchSync from "@/components/local/SwitchSync.vue";
import queOfrece from "@/components/local/queOfrece.vue";
import HeaderCenter from "@/components/local/HeadCenter.vue";
import hourTypes from "@/components/local/hourTypes.vue";
import localMixins from "@/mixins/local.js";

export default {
  components: {
    SwitchSync,
    FacebookLocations,
    GoogleLink,
    SkeletonCard,
    mapCenter,
    editData,
    editDetails,
    editLocation,
    editSocial,
    editSchedules,
    editPhotos,
    queOfrece,
    HeaderCenter,
    showError,
    hourTypes,
    editVideos
  }, // USAMOS COMPONENTE PARA LOS FILTROS DE TIPO COMBOBOX
  mixins: [commonMixins, localMixins],
  data() {
    return {
      showEdit: true, // TEMPORAL PARA SUBIDAS A PRO
      dataProfile: null,
      dataSites: [],
      showEditData: false,
      showEditDetails: false,
      showEditLocation: false,
      showEditSocial: false,
      showEditSchedules: false,
      showEditSchedulesType: null,
      showEditPhotos: false,
      showEditVideos: false,
      showError: false,
      messageError: null,
      loadingSync: false,
      loadingImportGoogle: false,
      img: {
        googleMaps: require("@/assets/img/google-maps-icon.svg"),
        facebook: require("@/assets/img/facebook.svg"),
        edit: require("@/assets/img/ico-edit-data.svg"),
        web: require("@/assets/img/ico-web.svg"),
        reservas: require("@/assets/img/ico-reservas.svg"),
        carta: require("@/assets/img/ico-carta.svg"),
        youtube: require("@/assets/img/ico-youtube.svg"),
        twitter: require("@/assets/img/ico-twitter.svg"),
        instagram: require("@/assets/img/ico-instagram.svg"),
        addPhoto: require("@/assets/img/ico-add-photo.svg"),
        active: require("@/assets/img/ico-active.svg"),
        disabled: require("@/assets/img/ico-disabled.svg"),
        noMap: require("@/assets/img/no-map.svg"),
        deliveryOtro: require("@/assets/img/delivery-otro.svg"),
        deliveryUbereats: require("@/assets/img/delivery-ubereats.svg"),
        deliveryGlovo: require("@/assets/img/delivery-glovo.svg"),
        deliveryDeliveroo: require("@/assets/img/delivery-deliveroo.svg"),
        deliveryJusteat: require("@/assets/img/delivery-justeat.svg"),
        facebookWhite: require("@/assets/img/ico-facebook-white.svg"),
        bing: require('@/assets/img/local/BIN.svg'),
        linkedin: require('@/assets/img/ico-linkedin.svg'),
        tiktok: require('@/assets/img/ico-tiktok.svg')
      },
      openGoogleLink: false,
      hasTokenGoogle: false,
      urlAuthGoogle: "",
      authCodeGoogle: "",
      verifyTokenGoogle: true,
      center: undefined,
      facebookConfig: {
        app_id: undefined,
        api_version: undefined,
      },
      facebookSelected: undefined,
      openFacebookLocations: false,
      hour_types: null,
    };
  },
  async created() {
    this.$store.commit("LOADING", true);
    this.loading = true;
    this.$store.commit("LOADING", true);
    this.verifyTokenGoogle = true;
    await this.getTokenGoogle();
    await this.getLocalMarketing();
    await this.getSitesLocalMarketing();
    await this.getCurrentFacebookLinkeded();
    this.center = Number(this.$store.getters.getNode);
    this.loading = false;
    this.$store.commit("LOADING", false);
  },
  computed: {
    boolAttributesGroups() {
      let groups = [];
      if (this.dataProfile) {
        this.dataProfile.bool_attributes.forEach((attr) => {
          if (groups.findIndex((e) => e.key === attr.group_name) === -1) {
            groups.push({key: attr.group_name, loading: false});
          }
        });
      }
      return groups;
    },
    sitesList() {
      return this.dataSites;
    },
  },
  watch: {
    filterApply: function () {
      this.getLocalMarketing();
      this.getSitesLocalMarketing();
    },
  },
  methods: {
    updateProfileIdFacebook() {
      /*if (data && data.result && data.result.length > 0) {
        this.facebookSelected = data.result[0].facebook_status.page_id;
      }*/
      this.getLocalMarketing();
    },
    addNewHours(item) {
      this.createNewSchedul(item);
    },
    editSchedules(type) {
      if (this.hasPremium || !type) {
        this.showEditSchedules = true;
        this.showEditSchedulesType = type;
      }
    },
    createNewSchedul(item) {
      if (this.hasPremium) {
        this.showEditSchedules = true;
        this.showEditSchedulesType = item.localize_display_name;
      }
    },
    unlinkCenterGoogle() {
      SrvLocal.setUnlinkCenterGoogle(this.$store.getters.getNode).then(
        (res) => {
          if (res.status === 200) {
            this.getLocalMarketing();
            this.getSitesLocalMarketing();
          }
        },
        () => {
        }
      );
    },
    changeSync(data) {
      this.dataSites[data.key].sync = data.state;
      SrvLocal.updateSyncSiteLocalMarketing(data.id, data.state);
    },
    async updateSiteLocalMarketing(site, id) {
      if (this.localMarketingExtended) {
        const dataSite = await SrvLocal.updateSiteLocalMarketing(site, id);
        const pos = this.dataSites.findIndex((e) => e.site_code == dataSite.data.site_code);
        this.$set(this.dataSites, pos, dataSite.data);
      }
    },
    async connectSiteLocalMarketing(site) {
      if (this.localMarketingExtended) {
        const dataSite = await SrvLocal.connectSiteLocalMarketing(site);
        const pos = this.dataSites.findIndex((e) => e.site_code == dataSite.data.site_code);
        this.$set(this.dataSites, pos, dataSite.data);
      }
    },
    refreshPage() {
      this.getLocalMarketing();
      this.getSitesLocalMarketing();
    },
    updateListGoogle(data) {
      if (data.success) {
        let data = this.dataRanking.find((item) => item.center_id === this.$store.getters.getNode);
        data && this.$set(data.accounts, "google", data.google_status);
      }
    },
    async facebookSetup() {
      await this.getCurrentFacebookLinkeded()
      this.facebookSelected = this.dataProfile.accounts.facebook.page_id;

      this.$nextTick(() => {
        this.openFacebookLocations = true;
      });
    },
    async getCurrentFacebookLinkeded() {
      const {status, data} = await SrvGlobal.getCurrentFacebookLinkeded();
      if (status === 200) {
        this.currentFacebookLinkeded = data.face_centers;
      }
    },
    async getTokenGoogle() {
      const {status, data} = await SrvGlobal.getHasToken();
      this.verifyTokenGoogle = false;
      if (status === 200) {
        if (data.has_valid_token) {
          this.hasTokenGoogle = true;
        } else {
          this.urlAuthGoogle = data.url;
          this.authCodeGoogle = data.code;
        }
      }
    },
    openAuthGoogle() {
      let windowObjectReference = null;
      let previousUrl = null;

      const popupCenter = ({url, title, w, h}) => {
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        const top = (height - h) / 2 / systemZoom + dualScreenTop;
        const newWindow = window.open(
          url,
          title,
          `
            scrollbars=yes,
            width=${w / systemZoom},
            height=${h / systemZoom},
            top=${top},
            left=${left}
            toolbar=no,
            menubar=no
          `
        );

        if (window.focus) newWindow.focus();
        return newWindow;
      };

      const openSignInWindow = (url, title) => {
        const w = 600;
        const h = 700;
        if (windowObjectReference === null || windowObjectReference.closed) {
          windowObjectReference = popupCenter({url, title, w, h});
        } else if (previousUrl !== url) {
          windowObjectReference = popupCenter({url, title, w, h});
          windowObjectReference.focus();
        } else {
          windowObjectReference.focus();
        }
        previousUrl = url;
      };

      let node = ``;

      if (this.$store.getters.getNodeLevel === 9) {
        if (this.$store.getters.getNode) {
          node += `?center=${this.$store.getters.getNode}`;
        }
      } else {
        if (this.$store.getters.getNode) {
          node += `?node=${this.$store.getters.getNode}`;
        }
      }

      window.localStorage.setItem("nodeForGoogle", node);

      openSignInWindow(this.urlAuthGoogle, "Get Token");
    },
    importGoogle(idBussines) {
      if (!this.loadingImportGoogle) {
        this.loadingImportGoogle = true;
        if (idBussines !== "" && typeof idBussines === "number") {
          SrvLocal.updateBunsiness(idBussines).then(
            (res) => {
              if (res.status === 200) {
                this.loadingImportGoogle = false;
                this.getLocalMarketing();
                this.getSitesLocalMarketing();
              }
            },
            () => {
              this.loadingImportGoogle = false;
            }
          );
        }
      }
    },
    sync(idBussines) {
      if (!this.loadingSync) {
        this.loadingSync = true;
        if (idBussines !== "" && typeof idBussines === "number") {
          SrvLocal.syncBunsiness(idBussines).then(
            (res) => {
              if (res.status === 200) {
                if (Object.keys(res.data).includes("success") && !res.data.success) {
                  this.messageError = res.data.error.message;
                  this.showError = true;
                }
                this.loadingSync = false;
              }
            },
            () => {
              this.loadingSync = false;
            }
          );
        }
      }
    },
    getClassStateAndLinked(color) {
      let classCss = "";
      if (color.toLowerCase() === "red") {
        classCss = "stateKo";
      } else {
        classCss = "stateOk";
      }
      return classCss;
    },
    getSitesLocalMarketing() {
      this.$store.commit("LOADING", true);
      // "BIN", "ELT", "MAP", "TRI", "YEL"
      SrvLocal.getSitesLocalMarketing().then(
        (res) => {
          if (res.status === 200) {
            this.dataSites = res.data.results.filter((e) => ["BIN", "MAP"].includes(e["site_code"]));
            this.$store.commit("LOADING", false);
          }
        },
        () => {
        }
      );
    },
    async getLocalMarketing() {
      this.$store.commit("LOADING", true);
      const res = await SrvLocal.getLocalMarketing();
      if (res.status === 200) {
        if (res.data.bool_attributes) {
          res.data.bool_attributes.map((e) => (e.isEdited = false));
        }
        this.dataProfile = res.data;
        this.facebookConfig = res.data.facebook_config;
        this.$store.commit("LOADING", false);
      }
    },
    getColorChartPercent: function (data) {
      let color = "";
      if (data < 41) {
        color = "#F93549";
      } else if (data >= 41 && data < 71) {
        color = "#FCAE19";
      } else {
        color = "#89C445";
      }
      let styles = "background: conic-gradient(" + color + " " + data + "%, #EEEEEE 0);border-color:" + color + ";";
      return styles;
    },
    async updateBoolAttributes(attrs) {
      attrs.forEach((attr) => {
        this.dataProfile.bool_attributes.find((e) => attr.id === e.id && attr.attribute === e.attribute).value = attr.value;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.without-text {
  color: rgb(145, 142, 142);
}

.notExtended {
  opacity: 0.35;
}

.chartMiniPie {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: conic-gradient(#7fd994 60%, #fff 0);
  border: 2px solid #7fd994;
  display: inline-block;
  vertical-align: middle;
}

.link-text {
  text-decoration: none;
}

.info-text {
  color: #214268;
}

.titleLastSync {
  &.validate {
    color: #ff8215;
  }
}

.stateOk {
  color: #7ed321;
}

.stateAccountChannel {
  margin-right: 20px;
}

.stateKo {
  color: #f93549;
}

.indicatorNotInfo {
  position: relative;

  &:after {
    content: " ";
    background-color: #ff7700;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
    top: 0;
    position: absolute;
    right: -15px;
  }
}

.btnEditar {
  background-color: #eff3fb;
  color: #4a90e2;
  font-size: 0.75rem;
  border-radius: 2px;
  padding: 2px 2px 2px 15px;

  span,
  img {
    display: inline-block;
    vertical-align: middle;
  }

  img {
    margin-left: 8px;
  }
}

#profileComplete {
  font-size: 0.875rem;

  .chartPercentCircle {
    width: 84px;
    height: 84px;

    p {
      font-size: 1.125rem;
    }
  }
}

#centerData {
  font-size: 0.875rem;
}

#location {
  iframe {
    max-width: 100%;
    height: 221px;
    display: block;
  }

  .noMap {
    width: 100%;
    display: block;
  }
}

#centerDetails {
  font-size: 0.875rem;

  .colPickup,
  .colDelivery {
    img {
      width: 23px;
      position: absolute;
      margin-left: 10px;
    }
  }

  .boxDeliveryService {
    background-color: #f2f2f2;
    border-radius: 4px;
    width: 100%;
    margin: 6px;
    width: calc(100% - 13px);

    &.noService {
      .urlDeliveryService {
        color: #cecece;
      }
    }

    .urlDeliveryService {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    img {
      vertical-align: middle;
    }
  }
}

#horarios {
  font-size: 0.875rem;

  .day {
    text-transform: lowercase;
  }
}

#social {
  font-size: 0.875rem;

  .icoSocial {
    width: 29px;
    float: left;
    margin-right: 18px;
  }
}

#galery {
  .photo {
    height: 92px;
    overflow: hidden;

    .categoryPhoto {
      font-size: 0.75rem;
    }
  }

  .buttonAddPhoto {
    background-color: #f3f3f5;
    border: 1px solid #4a90e2;
    display: inline-block;
    height: 74px;
    width: 74px;
    transition: all ease 0.3s;

    &:hover {
      img {
        transform: scale(1.3);
      }
    }
  }
}

#channels {
  .titleGrid span::after {
    width: 0;
  }

  .buttonConfig {
    transition: all ease 0.3s;
    color: #fff;
    font-size: 0.875rem;
    border-radius: 4px;
    font-weight: 600;
    padding: 8px 8px 8px 0;
    text-align: left;
    display: flex;
    align-items: center;
    word-break: break-all;
    width: auto;
    min-width: auto;

    &.facebook {
      background-color: #3b5998;
    }

    &.google {
      background-color: #5d9aff;

      &.unlink {
        background-color: #d0021b;
      }

      img {
        margin-left: 13px;
      }
    }

    &.connect {
      background-color: #5d9aff;
    }

    &.update {
      background-color: #34e0a1;
    }

    &.validate {
      background-color: #adccff;
      cursor: initial;
    }

    &.connect,
    &.update,
    &.validate {
      span:first-child {
        margin-left: 0;
      }
    }

    img {
      margin-left: 13px;
    }

    .box {
      display: inline-block;
      background-color: rgb(255, 255, 255);
      border-radius: 5px;
      padding: 2px;
      width: 26px;
      height: 28px;
      text-align: center;

      img {
        margin-left: 0;
      }
    }

    span:first-child {
      margin-left: 14px;
    }

    img,
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .rowChannel {
    border-top: 1px solid rgba(151, 151, 151, 0.4);
    font-size: 0.875rem;
    margin-bottom: 0;
    margin-top: 0;
    align-items: center;

    .row {
      align-items: center;
    }

    .colIcoChannel {
      align-items: center;
      display: flex;

      img {
        height: 38px;
      }
    }

    .nameChannel {
      font-size: 1rem;
      font-weight: 600;
    }
  }
}

#tableInfoActionsGoogle {
  background-color: #fff;
  margin: 0 20px;
  border-radius: 4px;

  .body,
  .head {
    display: flex;
    align-items: center;
    padding: 0 18px;

    .colCanal {
      width: 12%;
    }

    .colPerfil {
      width: 6%;
    }

    .colEstado {
      width: 40%;
      gap: 1em;
    }

    .colFecha {
      width: 42%;
      gap: 1em;
    }
  }

  .head {
    border-bottom: 1px solid #cecece;
    font-size: 0.875rem;
    padding: 10px 18px 5px 18px;
  }
}

#groupQueOfrece {
  background-color: #fff;
  margin: 0 12px;
  gap: 1em;
}
</style>
