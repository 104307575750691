<template lang="pug">
div.px-2.ma-0
  div
    .gridFull(v-if="isLoading")
      v-skeleton-loader(type="table")
    div.gridFull.narrow.borderDelivery(v-else)
      v-row.headTitleGrid
        v-col.titleGrid(md="4")
          translate(v-if="isGenericEntity") Business Ranking
          translate(v-else) Restaurant Ranking
        v-col(md="6")
          .d-flex.align-baseline
            .block-info
              div.d-flex.items-center.gap-0_5em
                span.text-12 Buscar:
                div.rounded.py-1.px-2.border-gray.d-flex.items-center.gap-0_5em
                  input(type="text" v-model="searchQuery")
                  img(src="@/assets/img/ico-search.svg" width="16")
        v-col.iconInfo.text-right(md="2")
          span.downloadExcel.mr-5(@click="downloadExcelCustom()")
            img(:src="imgCommons.download" width="10")
          .hide
            v-tooltip(left color="#222226" open-on-click=true max-width="450" nudge-bottom="50" nudge-left="10" content-class="tooltip-help tooltip-help-left")
              template(v-slot:activator="{ on, attrs }")
                img(:src="imgCommons.help" v-bind="attrs" v-on="on")
              span {{helpRanking}}



      v-row.headIndicators.text-left.align-center
        v-col.ml-2.d-flex.gap-0_5em.items-center
          translate #
          img.ml-2.cursor-pointer(:src="imgCommons.order" @click="sortData('name')" style="height: 9px")
        v-col.d-flex.align-center
          span Marca
        v-col.text-center
          translate % GPS
          img.ml-1.cursor-pointer(:src="imgCommons.order" @click="sortAppointments('gps')")

        v-col.d-flex.flex-col.justify-center.align-center.cNegative
          img(src="@/assets/img/detractoresPeople.svg" width="40")
          div.d-flex.items-center.justify-center
            translate Detractors
            img.ml-1.cursor-pointer(:src="imgCommons.order" @click="sortAppointments('gps_negative_reviews')")

        v-col.colPromotores.d-flex.flex-col.justify-center.align-center.cPositive
          img(src="@/assets/img/promotoresPeople.svg" width="40")
          div.d-flex.items-center.justify-center
            translate Promotores
            img.ml-1.cursor-pointer(:src="imgCommons.order" @click="sortAppointments('gps_positive_reviews')")

        v-col.d-flex.justify-center.items-center
          img(src="@/assets/img/aggregators/Just Eat.png" width="22")
          img.ml-2.cursor-pointer(:src="imgCommons.order" @click="sortData('site_jus_rating')" style="height: 9px")
        v-col.d-flex.justify-center.items-center
          img(src="@/assets/img/aggregators/glovo.svg" width="15")
          img.ml-2.cursor-pointer(:src="imgCommons.order" @click="sortData('site_glv_rating')" style="height: 9px")
        v-col.d-flex.justify-center.items-center
          img(src="@/assets/img/aggregators/Uber Eats.png" width="22")
          img.ml-2.cursor-pointer(:src="imgCommons.order" @click="sortData('site_ube_rating')" style="height: 9px")
        v-col.d-flex.justify-center.items-center
          span Nro de Opiniones
          img.ml-2.cursor-pointer(:src="imgCommons.order" @click="sortData('total_reviews')" style="height: 9px")

      v-row.bodyIndicators.text-left.align-center.mt-3.hover-resize(v-if="dataRanking" v-for="(item, index) in dataRanking" :key="index" :class="{'b-top' : index > 0, 'hide': item.average}")
        v-col.ml-2.py-0.d-flex.gap-0_5em
          span {{item.pos}}
          span(v-if="typeNode !== 'center'" :class="{'link-text': item.level.level > 0}" @click="goItemNextLevel(item,true)") {{item.name}}
          span(v-else) {{item.name}}
        v-col.py-0
          span {{item.level.name}}
        v-col.d-flex.align-center.justify-center
          span(:class="getColorPercent(item.gps)") {{ item.gps | formatNumber}} %
        v-col.d-flex.align-center.justify-center
          span.text-center {{ item.gps_negative_reviews }}
        v-col.d-flex.align-center.justify-center
          span.text-center {{ item.gps_positive_reviews }}
        v-col.py-0.d-flex.justify-center
          div.d-flex.justify-center.items-center(v-if="formatNumber(item['site_jus_rating']) === '-'")
            span -
          div.d-flex.justify-center.items-center(v-else)
            just-eat-rating(:ranking="+(item['site_jus_rating'].toFixed(2))")



        v-col.py-0.d-flex.justify-center
          div.d-flex.justify-center.items-center(v-if="formatNumber(item['site_glv_rating']) === '-'")
            span -
          div.d-flex.justify-center.items-center(v-else)
            GlovoRating(:percent="item['site_glv_rating'] || 0 ")
            span {{ $options.filters.PercentFromValue(item['site_glv_rating'], 5) | formatNumber }}%

        v-col.py-0.d-flex.justify-center
          div.d-flex.justify-center.items-center(v-if="formatNumber(item['site_ube_rating']) === '-'")
            span -
          div.d-flex.justify-center.items-center.gap-0_5em(v-else)
            img(:src="img.imgStar")
            span {{ item['site_ube_rating'] | formatNumber }}


        v-col.d-flex.justify-center
          span {{ item.total_reviews }}
</template>

<script>
import SrvNetwork from '@/services/delivery-network.js'
import commonMixins from "@/mixins/common.js"
import brandMixins from '@/mixins/brand'
import StarRating from '@/components/common/starRating.vue'
import GlovoRating from '@/components/common/GlovoRating.vue'
import justEatRating from '@/components/common/justEatRating.vue'

export default {
  props: ['paramCOD'],
  mixins: [commonMixins, brandMixins],
  components: { GlovoRating, StarRating, justEatRating },
  data() {
    return {
      searchQuery: undefined,
      ordering: '',
      current: 1,
      pageSize: 20,
      avg_data: {},
      dataRanking: [],
      dataAverage: [],
      img: {
        imgStar: require('@/assets/img/star.svg'),
      },
      filters: {
        province: '',
        nameItem: ''
      },
      labelSearch: this.$gettext('Search'),
      sortParam: 'rating_global',
      sortDesc: true,
      helpRanking: this.$gettext('Ranking of the restaurants of the whole group by number of opinions and valuation during the sectioned period, according to the type of restaurants. Use the Sites selector to see only your information. You can sort the columns and use the search engine to see the position of the restaurants with respect to the Company average. And the search engine that is further to the right to filter by province')
    }
  },
  created() {
    this.$store.dispatch('getAllSitesOpinions')
    this.$store.commit('LOADING', true)
    if (this.$store.getters.getNode > 0) {
      this.getRanking()
    }
  },
  mounted() {
    this.scroll()
  },
  computed: {
    indexStart() {
      return (this.current - 1) * this.pageSize
    },
    indexEnd() {
      return this.indexStart + this.pageSize
    },
    paginated() {
      // Init vars
      let filtered = false
      let el = this
      let arrFiltered = this.dataRanking.slice()
      let mainFormat = this.$options.filters.formatNumber((this.dataAverage.rating_global + 0.01), 1, 2).replace(',', '.')
      // Filter array
      if (this.filters.province.toLowerCase().trim()) {
        arrFiltered = arrFiltered.filter(function (item) {
          if (item.region) {
            let regionLow = item.region.toLowerCase()
            return (regionLow || '').includes(el.filters.province.toLowerCase().trim())
          }
        })
        arrFiltered.push({ 'average': true, 'level': '', 'name': '', 'region': '', 'rating_global': mainFormat, 'total_reviews': this.dataAverage.avg_reviews + 0.001 })
        arrFiltered = this.sortArray(arrFiltered)
        filtered = true
      }
      if (this.filters.nameItem.toLowerCase().trim()) {
        arrFiltered = arrFiltered.filter(c => c.name.toLowerCase().indexOf(this.filters.nameItem.toLowerCase().trim()) > -1)
        filtered = true
        arrFiltered.push({ 'average': true, 'level': '', 'name': '', 'region': '', 'rating_global': mainFormat, 'total_reviews': this.dataAverage.avg_reviews + 0.001 })
        arrFiltered = this.sortArray(arrFiltered)
      }

      if (!filtered) {
        arrFiltered.push({ 'average': true, 'level': '', 'name': '', 'region': '', 'rating_global': mainFormat, 'total_reviews': this.dataAverage.avg_reviews + 0.001 })
        arrFiltered = this.sortArray(arrFiltered)
        arrFiltered = arrFiltered.slice(0, this.indexEnd)
      }
      let rank = 0
      let arrFilteredCorrectIndex = arrFiltered.map((a) => {
        if (!Object.keys(a).includes('average')) {
          rank += 1
          a.pos = rank
        }
        return a
      })

      return arrFilteredCorrectIndex

    },
    correctIndex() {
      // var vm = this;
      return function (data) {
        let param = ''

        if (this.sortParam === 'total_reviews') {
          param = 'avg_reviews'
        } else {
          param = 'rating_global'
        }

        if (this.sortDesc) {
          if (data[this.sortParam] < this.dataAverage[param]) {
            return 0
          }
        } else {
          if (data[this.sortParam] > this.dataAverage[param]) {
            return 0
          }
        }
        return 1
      };

    },
    textAverage() {
      if (this.sortParam === 'total_reviews') {
        return this.typeNode !== 'center' ? this.$gettext('Average by Opinions') : this.$gettext('Brand Average by Opinions')
      } else {
        return this.typeNode !== 'center' ? this.$gettext('Average by Valuation') : this.$gettext('Brand Average by Valuation')
      }
    }
  },
  watch: {
    filterApply: function () {
      this.getRanking()
    },
    searchQuery() {
      this.getRanking()
    }
  },
  methods: {
    formatNumber(val, min = 0, max = 1) {
      if (typeof (val) !== 'string') {
        if (val === null) {
          return '-'
        }
        if (val === 0) {
          return '-'
        }
        return Number(val).toLocaleString('de-De', { minimumFractionDigits: min, maximumFractionDigits: max })
        // se deja en alemán para mostrar punto decimal en los miles return Number(val).toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
      }
      return val
    },
    sortArray(arrFiltered) {
      // Sort array
      arrFiltered = arrFiltered.sort((a, b) => {
        // return b[this.sortParam] - a[this.sortParam]
        let secondOrder = 'total_reviews'
        if (this.sortParam != 'rating_global') {
          secondOrder = 'rating_global'
        }
        if (b[this.sortParam] != a[this.sortParam]) {
          return b[this.sortParam] - a[this.sortParam]
        } else {
          return b[secondOrder] - a[secondOrder]
        }

      })
      if (!this.sortDesc) {
        arrFiltered = arrFiltered.reverse()
      }
      return arrFiltered
    },
    // OBTENEMOS INDICADORES
    getRankingAverage() {
      SrvNetwork.getRankingsAverage()
        .then(res => {
          if (res.status === 200) {
            this.dataAverage = res.data

          } else {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    next() {
      this.current++;
    },
    downloadExcelCustom() {
      this.downloadExcel({ ordering: this.ordering, search: this.searchQuery }, this.dataRanking.length)
    },
    getRanking: function (nextUrl = null, ordering = 'name') {
      if (!nextUrl) {
        SrvNetwork.getRankings(ordering, this.searchQuery)
          .then(res => {
            if (res.status === 200) {
              this.avg_data = res.data.avg_data
              this.dataRanking = res.data.results
              this.nextScrollUrl = res.data.next
            }
            this.$store.commit('LOADING', false)
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      } else {
        SrvNetwork.getRankingsNext(nextUrl)
          .then(res => {
            if (res.status === 200) {
              res.data.results.forEach(element => {
                this.dataRanking.push(element)
              });
              this.nextScrollUrl = res.data.next
              // this.$store.commit(types.SATISFACTION_RANKING, res.data)
              this.nextCall = false
            } else {
              // this.message = 'Se ha producido un error. Intentelo más tarde.'
            }
          }, () => {
            // this.message = 'Se ha producido un error. Intentelo más tarde.'
          })
      }
    },
    scroll() {
      window.onscroll = () => {
        if (this.$route.name === 'DeliveryRankingRestaurantsHome') {
          var body = document.body,
            html = document.documentElement
          var height = Math.max(body.scrollHeight, body.offsetHeight,
            html.clientHeight, html.scrollHeight, html.offsetHeight)
          height = parseInt(height, 10) - 1
          let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height || parseInt((document.documentElement.scrollTop + window.innerHeight).toFixed(0), 10) + 1 == height || (document.documentElement.scrollTop + window.innerHeight).toFixed(0) == height + 1
          if (this.nextScrollUrl && bottomOfWindow && !this.nextCall) {
            this.nextCall = true
            this.getRanking(this.nextScrollUrl)
          }
        }
      }
    },
    sortData(ordering) {
      if (this.ordering.includes('-')) {
        this.ordering = ordering
      } else {
        this.ordering = '-' + ordering
      }
      this.getRanking(null, this.ordering)
    },
    sortAppointments(param) {
      this.sortDesc = this.sortParam === param ? !this.sortDesc : true
      this.sortParam = param
    },
  }
}
</script>

<style lang="scss" scoped>
.sub-header {
  font-size: 12px;
  background: #F2F2F2;
  overflow: hidden;
  z-index: 0;
  border: solid 1px lightgray;
  margin: 11px -11px 0;
  border-left-color: gray;
  border-right-color: gray;
}

.colGPS {
  flex: 0 0 7%
}

.colDetractores {
  flex: 0 0 10%
}

.colPromotores {
  flex: 0 0 10%
}

.gridFull.narrow .content-row,
.gridFull.narrow .bodyIndicators {
  height: 100% !important;
}
</style>
